import {inject, injectable} from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type PowerCounterSigner from '@/common/data/PowerCounterSigner'

@injectable()
class BusinessResolutionPowersCounterSignerService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {
  }

  get(
    workspaceUuid: string,
    enrollmentId: number,
    businessApplicantId: number
  ): Promise<Array<PowerCounterSigner>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUuid}/enrollment/${enrollmentId}/applicant/${businessApplicantId}/countersigner`,
      {},
      ResponseTypes.Payload
    )
  }

  save(
    workspaceUuid: string,
    enrollmentId: number,
    businessApplicantId: number,
    DTO: PowerCounterSigner
  ): Promise<PowerCounterSigner> {
    return (DTO.counterSignerUuid)
      ? this.httpHandler.put(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUuid}/enrollment/${enrollmentId}/applicant/${businessApplicantId}/countersigner/${DTO.counterSignerUuid}`,
        DTO,
        {},
        ResponseTypes.Payload
      )
      : this.httpHandler.post(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUuid}/enrollment/${enrollmentId}/applicant/${businessApplicantId}/countersigner`,
        DTO,
        {},
        ResponseTypes.Payload
      )
  }

}

export default BusinessResolutionPowersCounterSignerService;
<template>
  <blt-modal-dialog
    :title="title"
    type="small-dialog"
    :showDialog="true"
    :cancelDisabled="false"
    :preventAutoClose="true"
    confirm-label="Confirm"
    show-confirm
    :clickOutsideToClose="true"
    :showFooter="true"
    :stateClass="'blt-id-uploader-dialog'"
    @cancel="cancel"
    @confirm="confirm"
    :confirmDisabled="!enableConfirm || loading"
  >
    <template v-slot:dialog-content>
      <div class="change-password-wrapper">
        <bltNotificationEmp v-if="messages" :messages="messages"></bltNotificationEmp>
        <jha-flex-wrapper>
          <jha-form-input
            type="password"
            :label="ChangePasswordConstant.CURRENT_PASSWORD"
            v-model="oldPassword"
            required
            outline
            :warning="errorMessageRequired"
          >
          </jha-form-input>
        </jha-flex-wrapper>
        <jha-flex-wrapper>
          <jha-form-input
            type="password"
            :label="ChangePasswordConstant.NEW_PASSWORD"
            v-model="newPassword"
            @blur="confirmNewPasswordChange('blur')"
            required
            outline
            :warning="errorMessageRequired"
          >
          </jha-form-input>
        </jha-flex-wrapper>
        <jha-flex-wrapper>
          <jha-form-input
            type="password"
            :label="ChangePasswordConstant.CONFIRM_NEW_PASSWORD"
            v-model="confirmNewPassword"
            required
            @blur="confirmNewPasswordChange('blur')"
            @change="confirmNewPasswordChange('change')"
            outline
            :showWarning="showErrorMessage"
            :warning="confirmPasswordErrorMessage"
          >
          </jha-form-input>
        </jha-flex-wrapper>
      </div>
    </template>
  </blt-modal-dialog>
</template>
<script lang="ts">
import { bltNotificationEmp } from "@/branchmanager/app/components/components.module";
import { BranchLanguageKeysConstant } from "@/branchmanager/app/constant/BranchLanguageKeysConstant";
import { ChangePasswordConstant } from "@/common/constant/ChangePasswordConstant";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import type INotificationFactory from "@/common/services/INotificationFactory";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import { defineComponent } from "vue";
import { useLocalStorage } from "@/common/stores/LocalStorageStore";
import bltModalDialog from "@/common/components/bltDialog/bltModalDialog.vue";
import { CommonService, ToastService } from "@/common/services/services.module";
import { WorkspaceStatesConstant } from "@/branchmanager/app/constant/WorkspaceStatesConstant";
import BltChangePasswordVerbiage from "@/common/components/bltChangePassword/BltChangePasswordVerbiage";
import type IRouterService from "@/common/services/utils/IRouterService";

export default defineComponent({
  name: "BltChangePassword",
  emits: ["closeDialog", "changePassword", "passwordChanged"],
  props: {
    login: {
      type: String,
      required: true,
    }
  },
  setup() {
    return {
      workspaceMetadataStore: $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore"),
      notificationFactory: $injByInterface<INotificationFactory>("INotificationFactory"),
      commonService: $inj(CommonService),
      localStore: useLocalStorage(),
      router: $injByInterface<IRouterService>("IRouterService"),
      toastService: $inj(ToastService),
    };
  },
  data() {
    return {
      loading: false,
      errorMessageRequired: BranchLanguageKeysConstant.BRANCH_CHANGE_PASSWORD_REQUIRED,
      errorMessagePasswordNotMatch: BranchLanguageKeysConstant.BRANCH_CHANGE_PASSWORD_NOT_MATCH,
      confirmPasswordErrorMessage: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      ChangePasswordConstant
    };
  },
  computed: {
    title() {
      return `Changing password for ${this.login}`
    },
    enableConfirm() {
      return this.oldPassword && this.newPassword && this.newPassword === this.confirmNewPassword;
    },
    messages() {
      return this.workspaceMetadataStore.notification.messages;
    },
    showErrorMessage() {
      return this.confirmPasswordErrorMessage?.length;
    }
  },
  methods: {
    confirm(): void {

      // todo(mikol): Make a localized version of error management...
      this.notificationFactory.clearSystemMessages();

      this.loading = true;

      this
        .commonService
        .changePassword({
          newPassword: this.newPassword,
          oldPassword: this.oldPassword,
          login: this.login
        })
        .then(() => {
          this.toastService.success(BltChangePasswordVerbiage.PASSWORD_CHANGED);
          this.localStore.clearAuthenticationData()
          this.$emit('closeDialog')
          this.router.push({ name: WorkspaceStatesConstant.LOGIN.STATE, force: true });
        })
        .catch(this.notificationFactory.showData)
        .finally(() => this.loading = false)

    },

    cancel(): void {
      this.notificationFactory.clearSystemMessages();
      this.$emit("closeDialog");
    },

    confirmNewPasswordChange(type: string): void {
      if (type === "blur") {
        if (this.confirmNewPassword && this.confirmNewPassword != this.form.newPassword) {
          this.confirmPasswordErrorMessage = this.errorMessagePasswordNotMatch;
        }
      }
      if (type === "change") {
        this.confirmPasswordErrorMessage = this.confirmNewPassword ? "" : this.errorMessageRequired;
        if (this.confirmNewPassword && this.confirmNewPassword === this.form.newPassword) {
          this.confirmPasswordErrorMessage = "";
        }
      }
    }
  },
  components: {
    bltNotificationEmp,
    bltModalDialog,
  }
});
</script>

<style scoped>
.change-password-wrapper {
  padding: var(--jh-space-300) 0px var(--jh-space-600) 0px;
  width: 100%;
}

jha-flex-wrapper > * {
  flex: 1;
  margin-bottom: auto;
}

jha-flex-wrapper {
  margin-bottom: 4px;
}

jha-flex-wrapper:last-child {
  margin-bottom: 0px;
}

.change-password-wrapper :deep(.jha-notification) {
  margin-top: var(--jh-space-300);
  margin-bottom: var(--jh-space-100);
}
</style>

<script lang="ts">
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import { LanguageFactory, WorkflowFactory } from "@/common/services/services.module";
import { defineComponent } from "vue";
import { QueryPropertyKey } from "@/open/app/factories/config/queryProperties.enum";
import { QueryPropertiesService } from "@/open/app/factories/config/queryProperties.service";
import "@banno/jha-wc/src/icons/jha-icon-chevron-back.js";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import "@banno/jha-wc/src/buttons/jha-button/jha-button.js";
import { bltNotification } from "@/common/components/appch.components.module";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import "@banno/jha-wc/src/icons/jha-icon-search.js";
import type { RouteLocationMatched } from "vue-router";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import type IWizardProps from "@/common/services/Workspace/IWizardProps";
import { ErrorHandler } from "../handlers/ErrorHandler";

export default defineComponent({
  name: "bltWizard",
  data: () => ({
    wizardProps: {} as IWizardProps,
    title: "",
    alternateTitle: "" as string | undefined,
    prevLoading: false,
    isShowSearchBar: false,
    secondaryTitle: "" as string
  }),
  setup() {
    const languageFactory = $inj(LanguageFactory);
    const broadcastService = $inj(BroadcastService);
    const queryPropertiesService = $inj(QueryPropertiesService);
    const workflowFactory = $inj(WorkflowFactory);
    const workspaceMetadataStore = $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore");
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    return {
      languageFactory,
      broadcastService,
      queryPropertiesService,
      workflowFactory,
      workspaceMetadataStore,
      workspaceStore
    };
  },
  async created() {
    this.prevLoading = this.wizardProps.loading;
    this.broadcastService.on(BroadcastConstant.BLTFORM_CREATED_UPDATED, () => {
      this.handleBltFormCreateBroadcast();
    });
  },
  computed: {
    isHeadless: function () {
      const tempisHeadless = !!this.queryPropertiesService.getBool(QueryPropertyKey.HEADLESS);
      return tempisHeadless;
    },
    currentState: function () {
      const tempcurrentState = this.workflowFactory.getState() || { nextStateId: true, description: "" };
      return tempcurrentState;
    },
    backButtonDisabled: function () {
      return (
        this.wizardProps.disabled ||
        this.currentState.back === false ||
        this.wizardProps.loading ||
        this.wizardProps.backDisabled ||
        this.currentState.id === 1
      ) && !this.wizardProps.alternateBackEnabled;
    },
    // This metheod not used because first state is not determined. once done ll change the logic
    showBackButton: function () {
      return !this.wizardProps.hideBackButton;
    },
    messages: function () {
      return this.workspaceMetadataStore.notification.messages;
    },
    currentScreenNumber() {
      return this.workspaceStore.transitionInfo.currentNumber;
    },
    continueShowPreloader() {
      /**The stage from start of application till the start of workflow transitioning is called PRELOADING */
      return this.workflowFactory.getPreloadingStatus();
    },
    isContentLoading() {
      /**Every workflow state/module moving next or previous and calling APIs */
      return this.workspaceMetadataStore.wizardProps.loading || this.workflowFactory.getTransitioning();
    },
    isLoading() {
      return this.workspaceMetadataStore.wizardProps.loading;
    },
    loaderMessages() {
      return this.workspaceMetadataStore.wizardProps.loaderMessages;
    },
    isTransitioning() {
      return this.workflowFactory.getTransitioning();
    },
    cardContentCss() {
      return this.wizardProps.contentCardClass;
    }
  },
  beforeMount: function () {
    this.wizardProps = this.workspaceMetadataStore.wizardProps;

    this.prevLoading = this.wizardProps.loading;
  },
  methods: {
    onWrapperFormKeyPressed(event: any) {
      if (event.key === "Enter") {
        window.document.getElementById("buttonContinue")?.click();
      }
    },
    async doBack() {
      if (!this.backButtonDisabled) {
        try {
          this.wizardProps.back ? await this.wizardProps.back() : await this.workflowFactory.previous();
          this.broadcastService.broadcast(BroadcastConstant.WIZARD_NAVIGATED, "back");
        } catch (err) {
          ErrorHandler.handleNavigationErrorForWorkspace(
            this.workspaceStore.workspaceUUID,
            this.workspaceStore.trackerUUID
          );
        }
      }
    },
    doAlternateBack() {
      if (this.wizardProps.alternateBackFunc) {
        this.wizardProps.alternateBackFunc();
      }
    },
    doSecondaryAction(event: Event) {
      if (this.wizardProps.secondaryAction) {
        this.wizardProps.secondaryAction(event);
      }
    },
    setDefaultFocus(newVal: boolean, oldVal: boolean) {
      if (!newVal && oldVal) {
        this.$nextTick(() => {
          const firstElement = this.$el.length ? this.$el.find("INPUT") : [];
          return firstElement.length ? firstElement[0].focus() : null;
        });
      }
    },
    handleBltFormCreateBroadcast() {
      const newVal = this.wizardProps.loading;
      const oldVal = this.prevLoading;
      if (newVal !== oldVal) {
        this.broadcastService.broadcast(BroadcastConstant.OPEN_FORM_LOADING, newVal);
        this.setDefaultFocus(newVal, oldVal);
      }
      this.updateTitle();
    },
    updateTitle() {
      if (this.wizardProps.hideTitle) return;
      const overrideMsg = this.wizardProps.bltUiText;
      this.secondaryTitle = "";
      this.alternateTitle = this.wizardProps.alternateTitle
      const innerScreenSecondaryTitle = this.wizardProps.secondaryTitle;

      if (overrideMsg) {
        if (this.wizardProps.staticTitle) {
          this.title = overrideMsg;
        } else {
          this.languageFactory.get(overrideMsg).then((title) => {
            this.title = title;
          });
        }
      } else {
        this.workflowFactory.getTitle().then((title) => {
          this.title = title;
        });
      }
      if (innerScreenSecondaryTitle) this.secondaryTitle = innerScreenSecondaryTitle;
    },
    isWorkflowGrandchild(state: RouteLocationMatched[]) {
      const routeMatched = state.slice();
      routeMatched.pop();
      routeMatched.pop();
      const grandParent = routeMatched.pop();
      return grandParent?.name === "workflow";
    },
    showSearchBar() {
      this.isShowSearchBar = !this.isShowSearchBar;
      this.broadcastService.broadcast(BroadcastConstant.WIZARD_TOGGLE_SEARCH, this.isShowSearchBar);
    }
  },
  components: { bltNotification }
});
</script>
<template>
  <section :class="wizardProps.contentMarginClass">
    <div v-if="!continueShowPreloader" class="transition-wrapper" id="contentcard">
      <div v-if="isContentLoading" class="contentloader-parent"></div>
      <blt-contentloader v-if="isContentLoading"
                         :contentLoader="isContentLoading"
                         :contents="loaderMessages">
      </blt-contentloader>

      <blt-card>
        <template v-slot:header>
          <button
            class="header-back-button"
            type="button"
            @click="doBack"
            :disabled="backButtonDisabled"
            :aria-label="wizardProps.alternateBackText"
            v-if="
              !wizardProps.alternateBackEnabled &&
              showBackButton &&
              !wizardProps.hideNavigation &&
              currentScreenNumber > 1
            "
            :class="{ backDisbaled: backButtonDisabled }"
          >
            <jha-icon-chevron-back></jha-icon-chevron-back>
          </button>
          <button
            class="header-back-button"
            :disabled="wizardProps.disabled || wizardProps.loading || wizardProps.backDisabled"
            @click="doAlternateBack"
            v-if="wizardProps.alternateBackEnabled && showBackButton && !wizardProps.hideNavigation"
            :class="{ backDisbaled: backButtonDisabled }"
            :aria-label="wizardProps.alternateBackText"
          >
            {{ wizardProps.alternateBackText }}
            <jha-icon-chevron-back></jha-icon-chevron-back>
          </button>
          <h1 v-if="!wizardProps.hideTitle">
            {{ (alternateTitle || title) ?? "&nbsp;" }}
            <span class="innerTitle" v-if="secondaryTitle !== ''">{{ secondaryTitle }}</span>
          </h1>

          <div class="search-icon" v-if="wizardProps.searchIcon" @click="showSearchBar">
            <button>
              <jha-icon-search :class="{ 'jha-icon-search-color-active': isShowSearchBar }"></jha-icon-search>
            </button>
          </div>
        </template>

        <template v-slot:body>
          <blt-progress-bar
            :showDeterminate="wizardProps.showDeterminateProgressBar"
            v-if="!wizardProps.hideProgressbar"
          ></blt-progress-bar>
          <blt-notification v-if="!wizardProps.hideErrorNotification && messages.length" :messages="messages" />
          <div :class="cardContentCss" class="card-content">
            <div :class="{ headless: isHeadless }" class="w-100">
              <form @keypress="onWrapperFormKeyPressed">
                <slot>
                  <!-- the transition element comes in here -->
                </slot>
              </form>
            </div>
          </div>
        </template>
      </blt-card>
      <blt-preloader></blt-preloader>
    </div>
  </section>
</template>
<style>
.headless {
  padding: 0;
  background-color: aliceblue;
}
.backDisbaled {
  opacity: var(--jh-opacity-disabled, 0.3);
  cursor: not-allowed;
}
.jha-icon-search-color-active {
  fill: var(--primary-button-color);
}
</style>

import { inject, injectable } from "inversify";
import {isUndefined} from "lodash";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import HttpHandler from "@/common/services/connect/HttpHandler";
import DEPENDENCYTYPES from "../dependency.types";
import type IRouterService from "./utils/IRouterService";
import { WorkspaceStatesConstant } from "@/branchmanager/app/constant/WorkspaceStatesConstant";
import { useLocalStorage } from "@/common/stores/LocalStorageStore";
import type RestResponse from "@/common/data/RestResponse";

export type ChangePasswordRequest = {
  oldPassword: string,
  newPassword: string,
  login: string,
}

export type ChangePasswordResult = {

}

@injectable()
class CommonService {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(DEPENDENCYTYPES.IRouterService) private router: IRouterService,
    private localStore = useLocalStorage()
  ) {}

  logout() {
    return this.httpHandler
      .post(`${SERVICE_PATH_CONSTANTS.LAUNCHPAD}/logout`, {}, {}, ResponseTypes.Payload)
      .then(() => {
        this.router.push({ name: WorkspaceStatesConstant.LOGIN.STATE,
          params: {loginStatus: 'logout'}
        });
      })
      .then(() => {
        this.localStore.clearAuthenticationData()
      });
  }

  changePassword(request: ChangePasswordRequest): Promise<RestResponse<ChangePasswordResult>> {
    return this.httpHandler.post(
      "/v1/launchpadREST/changepassword",
      {
        password: request.oldPassword,
        newPassword: request.newPassword,
        login: request.login,
        latitude: null,
        longitude: null,
        locationAccuracyMeters: null,
        forceVerificationNewDevice: null,
        deviceGuid: this.localStore.guid
      },
      undefined,
      ResponseTypes.Data
    );
  }

  appCode = "";
  actions = {
    navOrder: -1,
    condition: true,
    title: "Actions:",
    navProperties: [
      {
        description: "Logout",
        action: this.logout,
        showConfirm: true,
        faIcon: "fa fa-power-off",
        faIconColor: "#bd362f"
      }
    ]
  };

  getNavItems() {
    return this.httpHandler
      .get(`${SERVICE_PATH_CONSTANTS.LAUNCHPAD}/active/app`, {}, ResponseTypes.Data)
      .then((response) => {
        return response.apps;
      });
  }

  addAction(obj: any) {
    if (!isUndefined(obj.scope)) {
      obj.scope.on("$destroy", () => {
        this.removeAction(obj);
      });
    }

    this.actions.navProperties.push(obj);
  }

  removeAction(obj: { description: string }) {
    this.actions.navProperties = this.actions.navProperties.filter((action) => {
      return action.description !== obj.description;
    });
  }

  getNavActions() {
    return this.actions;
  }

  getAppCode() {
    return this.appCode;
  }

  setAppCode(code: string) {
    this.appCode = code;
  }
}

export default CommonService;

import {isUndefined} from "lodash";
import ServiceUrlFactory from "@/common/services/Service/ServiceUrlFactory/ServiceUrlFactory";
import NotificationFactory from "@/common/services/notificationFactory";
import {inject, injectable} from "inversify";
import {DisplayConstant} from "@/common/constant/DisplayConstant";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import FACTORY_MSG from "@/common/messages/factory.messages";
import HttpHandler from '@/common/services/connect/HttpHandler'
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class PrintFactory {
    constructor(
        @inject(NotificationFactory) private notificationFactory: NotificationFactory,
        @inject(ServiceUrlFactory) private serviceUrlFactory: ServiceUrlFactory,
        @inject(HttpHandler) private httpHandler: HttpHandler
    ) {
    }

    printAdminPdf(disclosureCode: string) {
        return this.httpHandler.get(
            `{{boltsbranch}}/disclosure/${disclosureCode}/pdf/token`,
            {},
            ResponseTypes.Payload
        ).then(token => {
            window.open(
                `/v1/leafREST/disclosure/pdf?token=${token}`,
                DisplayConstant.PRINT,
                ""
            );
        })
    }

    async printByToken(token: string) {
        const PRINT_PDF = "/print/pdf?token=";
        return await this.serviceUrlFactory
            .getOne(SERVICE_PATH_CONSTANTS.SERVICE_CODE_BOLTSBRANCH)
            .then((response: string) => {
                return new Promise<void>((res, rej) => {
                    const printWindow = window.open(
                        response + PRINT_PDF + token,
                        DisplayConstant.PRINT,
                        ""
                    );

                    /**
                     * There is a race condition where if you print a disclosure, you need to wait for the token to be
                     * used before you can run validation rules to check if a user printed the disclosure. Since the
                     * print modal might be on a different domain, I can't use addEventListener, so instead, we are
                     * waiting for the user to close the print modal OR go back to the original app before we finish
                     * this promise.
                     */
                    const interval = setInterval(() => {
                        if (printWindow?.closed || window.document.hasFocus()) {
                            res()
                            clearInterval(interval);
                        }
                    }, 100);


                    if (printWindow === null || isUndefined(printWindow)) {
                        this.notificationFactory.error(FACTORY_MSG.PRINT_TOKEN.PRINT_TOKEN_LOG_ERROR);
                        rej()
                    }
                });
            })
    }
}

export default PrintFactory;

<script lang="ts">
import { $inj } from "@/common/decorators/depinject";
import { defineComponent, ref } from "vue";
import FullNameFilter from "@/common/services/filters/fullNameFilter";
import { BltSearchItemConstant } from "@/common/constant/bltSearchItemConstant";
import {
  EnrollmentSummaryFilter,
  FullApplicantNameFilter,
  PhoneNumberFilter,
  SearchDateFilter
} from "@/common/services/services.module";
import OPERATION_TYPES from "@/common/enums/operationTypeEnum";
import WorkspaceConstants from "@/common/constant/WorkspaceConstants";
import { isEmpty } from "lodash";
import type IPerson from "@/common/models/IPerson";
import type IBusiness from "@/common/models/IBusiness";
import type IAddress from "@/common/models/IAddress";
import workspaceHistoryDialog from '@/branchmanager/app/components/workspaceHistoryDialog/workspaceHistoryDialog.vue'
import dayjs from "dayjs";

export default defineComponent({
  name: "bltSearchDetail",
  emits: ["redirectWorkspace", "addApplicant"],
  props: {
    bltPersonBusinessDetail: {
      type: Object,
      default: () => ({})
    },
    bltWorkspaceDetail: {
      type: Object,
      default: () => ({})
    },
    bltDetailType: {
      type: String,
      retured: true
    }
  },
  setup() {
    const fullNameFilter = $inj(FullNameFilter);
    const fullApplicantNameFilter = $inj(FullApplicantNameFilter);
    const enrollmentSummaryFilter = $inj(EnrollmentSummaryFilter);
    const searchDateFilter = $inj(SearchDateFilter);
    const phoneNumberFilter = $inj(PhoneNumberFilter);
    const showHistoryDialog = ref(false)
    return {
      fullNameFilter,
      fullApplicantNameFilter,
      enrollmentSummaryFilter,
      searchDateFilter,
      phoneNumberFilter,
      showHistoryDialog
    };
  },
  data() {
    return {
      BltSearchItemConstant,
      applicantTypes: OPERATION_TYPES,
      WorkspaceConstants
    };
  },
  computed: {
    setInfoTitle(): string {
      if (this.bltPersonBusinessDetail.applicantType === WorkspaceConstants.PERSON)
        return WorkspaceConstants.PERSON_INFO_LABEL;
      else return WorkspaceConstants.BUSINESS_INFO;
    },
    isPerson(): boolean {
      return this.bltPersonBusinessDetail.applicantType === WorkspaceConstants.PERSON;
    },
    isBusiness(): boolean {
      return this.bltPersonBusinessDetail.applicantType === WorkspaceConstants.BUSINESS;
    },
    isBltPersonBusinessDetail(): boolean {
      return !!Object.keys(this.bltPersonBusinessDetail).length;
    },
    isBltWorkspaceDetail(): boolean {
      return !!Object.keys(this.bltWorkspaceDetail).length;
    }
  },
  methods: {
    setPersonFullname(applicant: { applicantType?: string; person?: IPerson; business?: IBusiness }): string {
      return this.fullApplicantNameFilter.fullApplicantName(applicant);
    },
    setEnrollmentSummary(enrollment: {
      enrollmentType?: string | undefined;
      enrollmentState?: string | undefined;
      createdDate?: string;
    }): string {
      return this.enrollmentSummaryFilter.enrollmentSummary(enrollment);
    },
    redirectWorkspace(): void {
      this.$emit("redirectWorkspace");
    },
    setCreatedDate(date: { createdDate?: Date; dateOfBirthIso?: Date; createdDateIso?: Date }): string {
      return this.searchDateFilter.searchDate(date);
    },
    getDOBDate(person: { dateOfBirth: string }): string {
      return person && person.dateOfBirth
          ? dayjs(person.dateOfBirth, "YYYY-MM-DD").format("MM/DD/YYYY")
          : "";
    },
    setAddress(addresses: IAddress[]): string {
      if (!isEmpty(addresses)) {
        const address = addresses[0];
        return `${address.address1 || ""} ${address.address2 || ""} ${address.city ? address.city + "," : ""} ${
          address.state || ""
        } ${address.zipCode || ""}`;
      }
      return "";
    },
    setPhoneNumber(phone: number | string | undefined): string {
      return this.phoneNumberFilter.phonenumber(phone);
    },
    addApplicantToWorkspace(): void {
      this.$emit("addApplicant");
    }
  },
  components: {
    workspaceHistoryDialog
  }
});
</script>
<template>
  <div class="search-details" v-if="isBltWorkspaceDetail && bltDetailType == WorkspaceConstants.WORKSPACETYPE">
    <!-- WORKSPACE -->
    <div class="d-flex">
      <div class="jha-icon">
        <jha-icon-fi class="icon-right-space"></jha-icon-fi>
      </div>
      <div class="primary-text">{{ WorkspaceConstants.WORKSPACE_INFO }}</div>
    </div>

    <div class="row row-space pt-16 pb-16">
      <div class="column">
        <div class="secondary-text bold-text">{{ WorkspaceConstants.UUID }}</div>
        <div class="secondary-value">{{ bltWorkspaceDetail.workspaceUUID }}</div>
      </div>
      <div class="column">
        <div class="secondary-text bold-text">{{ WorkspaceConstants.ENROLLMENTS }}</div>
        <div @click="showHistoryDialog = true" class="secondary-value" v-for="enrollment in bltWorkspaceDetail.enrollments" :key="enrollment">
          <span class="text-link">{{ setEnrollmentSummary(enrollment) }}</span>
        </div>
      </div>
    </div>
    <div class="row row-space pt-16 pb-16">
      <div class="column">
        <div class="secondary-text bold-text">{{ WorkspaceConstants.APPLICANTS }}</div>
        <template v-if="bltWorkspaceDetail?.applicants?.length">
          <div class="secondary-value" v-for="applicant in bltWorkspaceDetail.applicants" :key="applicant">
            {{ setPersonFullname(applicant) }}
          </div>
        </template>
        <template>
          <div class="secondary-value">{{ applicantTypes.NEW_APPLICANT }}</div>
        </template>
      </div>
    </div>
    <div class="row row-space">
      <div class="column column-mobile">
        <jha-button primary @click="redirectWorkspace()">
          {{ WorkspaceConstants.OPEN_WORKSPACE }}
        </jha-button>
      </div>
    </div>
  </div>

  <div class="search-details" v-if="isBltPersonBusinessDetail && bltDetailType == WorkspaceConstants.APPLICANTTYPE">
    <!-- APPLICANTS -->
    <div class="d-flex">
      <div class="jha-icon">
        <jha-icon-information style="fill: #5F6468" class="icon-right-space"></jha-icon-information>
      </div>
      <div class="primary-text">{{ setInfoTitle }}</div>
    </div>

    <div
      class="row row-space pt-16 pb-16"
      v-if="
        bltPersonBusinessDetail?.addresses?.length > 0 ||
        getDOBDate(bltPersonBusinessDetail?.person) ||
        bltPersonBusinessDetail?.business?.dateOfEstablishment
      "
    >
      <div class="column" v-if="isPerson && getDOBDate(bltPersonBusinessDetail?.person)">
        <div class="secondary-text bold-text">{{ WorkspaceConstants.DOB_LABEL }}</div>
        <div class="secondary-value">{{ getDOBDate(bltPersonBusinessDetail?.person) }}</div>
      </div>
      <div class="column" v-if="isBusiness && bltPersonBusinessDetail?.business?.dateOfEstablishment">
        <div class="secondary-text bold-text">{{ WorkspaceConstants.DATE_ESTABLISHMENT_LABEL }}</div>
        <div class="secondary-value">{{ bltPersonBusinessDetail?.business?.dateOfEstablishment }}</div>
      </div>
      <div class="column" v-if="bltPersonBusinessDetail?.addresses?.length > 0">
        <div class="secondary-text bold-text">{{ WorkspaceConstants.ADDRESS_LABEL }}</div>
        <div class="secondary-value">
          {{ setAddress(bltPersonBusinessDetail?.addresses) }}
        </div>
      </div>
    </div>
    <div
      class="row row-space pt-16 pb-16"
      v-if="bltPersonBusinessDetail?.phones?.length || bltPersonBusinessDetail?.emails?.length"
    >
      <div class="column">
        <div
          class="secondary-text bold-text"
          v-if="bltPersonBusinessDetail?.phones?.length && bltPersonBusinessDetail?.phones?.length <= 1"
        >
          {{ WorkspaceConstants.PHONE_LABEL }}
        </div>
        <template v-if="bltPersonBusinessDetail?.phones?.length === 1">
          <div class="secondary-value">
            {{ setPhoneNumber(bltPersonBusinessDetail?.phones[0].phoneNumber) }}
          </div>
        </template>
        <jha-collapsible-section class="collapsible-address" v-if="bltPersonBusinessDetail?.phones?.length > 1">
          <div slot="header">
            <div class="secondary-text bold-text">{{ WorkspaceConstants.PHONE_LABEL }}</div>
            <template v-if="bltPersonBusinessDetail?.phones?.length">
              <div class="secondary-value">
                {{ setPhoneNumber(bltPersonBusinessDetail?.phones[0].phoneNumber) }}
              </div>
            </template>
          </div>
          <div slot="body" v-if="bltPersonBusinessDetail?.phones?.length > 0">
            <template v-for="(phone, index) in bltPersonBusinessDetail.phones" :key="phone">
              <div v-if="index > 0">
                <div class="secondary-text bold-text pt-32">
                  <span class="phoneTitle">{{ phone?.phoneType?.toLowerCase() }}</span>
                  <span>{{ WorkspaceConstants.PHONETEXT }}</span>
                </div>
                <div class="secondary-value">
                  {{ setPhoneNumber(phone?.phoneNumber) }}
                </div>
              </div>
            </template>
          </div>
        </jha-collapsible-section>
      </div>
      <div class="column">
        <div
          class="secondary-text bold-text"
          v-if="bltPersonBusinessDetail?.emails?.length && bltPersonBusinessDetail?.emails?.length <= 1"
        >
          {{ WorkspaceConstants.EMAIL_LABEL }}
        </div>
        <template v-if="bltPersonBusinessDetail?.emails?.length === 1">
          <div class="secondary-value">
            {{ bltPersonBusinessDetail?.emails[0]?.emailAddress }}
          </div>
        </template>
        <jha-collapsible-section class="collapsible-address" v-if="bltPersonBusinessDetail?.emails?.length > 1">
          <div slot="header">
            <div class="secondary-text bold-text">{{ WorkspaceConstants.EMAIL_LABEL }}</div>
            <template v-if="bltPersonBusinessDetail?.emails?.length">
              <div class="secondary-value">{{ bltPersonBusinessDetail?.emails[0]?.emailAddress }}</div>
            </template>
          </div>
          <div slot="body" v-if="bltPersonBusinessDetail?.emails?.length > 0">
            <template v-for="(email, index) in bltPersonBusinessDetail.emails" :key="email">
              <div v-if="index > 0">
                <div class="secondary-text bold-text pt-32">
                  <span class="phoneTitle">{{ email?.emailType.toLowerCase() }} </span> {{ WorkspaceConstants.EMAIL }}
                </div>
                <div class="secondary-value">{{ email?.emailAddress }}</div>
              </div>
            </template>
          </div>
        </jha-collapsible-section>
      </div>
    </div>
    <div
      class="row row-space pt-16 pb-16"
      v-if="!bltPersonBusinessDetail.coreId && isPerson && setCreatedDate(bltPersonBusinessDetail)"
    >
      <div class="column">
        <div class="secondary-text bold-text">{{ WorkspaceConstants.APPLICANT_CREATED }}</div>
        <div class="secondary-value">{{ setCreatedDate(bltPersonBusinessDetail) }}</div>
      </div>
    </div>
    <div class="row row-space">
      <div class="column column-mobile">
        <jha-button sync="true" primary @click="addApplicantToWorkspace()">
          {{ WorkspaceConstants.ADD_WORKSPACE }}
        </jha-button>
      </div>
    </div>
    <!-- New line -->
    <template v-if="isBltWorkspaceDetail">
      <div class="divider pb-16 ml-44"></div>
      <div class="existing-workspace-details">
        <div class="d-flex">
          <div class="jha-icon">
            <jha-icon-fi class="icon-right-space"></jha-icon-fi>
          </div>
          <div class="primary-text bold-text">{{ WorkspaceConstants.EXISTING_WORKSPACE_INFO }}</div>
        </div>

        <div class="row row-space pt-16 pb-16">
          <div class="column" v-if="bltWorkspaceDetail.workspaceUUID">
            <div class="secondary-text bold-text">{{ WorkspaceConstants.UUID }}</div>
            <div class="secondary-value">{{ bltWorkspaceDetail.workspaceUUID }}</div>
          </div>
          <div class="column" v-if="bltWorkspaceDetail.enrollments?.length > 0">
            <div class="secondary-text bold-text">{{ WorkspaceConstants.ENROLLMENTS }}</div>
            <div class="secondary-value" v-for="enrollment in bltWorkspaceDetail.enrollments" :key="enrollment">
              {{ setEnrollmentSummary(enrollment) }}
            </div>
          </div>
        </div>
        <div class="row row-space pt-16 pb-16">
          <div class="column">
            <div class="secondary-text bold-text">{{ WorkspaceConstants.APPLICANTS }}</div>
            <template v-if="bltWorkspaceDetail?.applicants && bltWorkspaceDetail?.applicants?.length">
              <div class="secondary-value" v-for="applicant in bltWorkspaceDetail.applicants" :key="applicant">
                {{ setPersonFullname(applicant) }}
              </div>
            </template>
            <div class="secondary-value" v-else>{{ applicantTypes.NEW_APPLICANT }}</div>
          </div>
        </div>
        <div class="row row-space">
          <div class="column column-mobile">
            <jha-button sync="true" primary default @click="redirectWorkspace()">
              {{ WorkspaceConstants.OPEN_EXISTING_WORKSPACE }}
            </jha-button>
          </div>
        </div>
      </div>
    </template>
  </div>

  <workspace-history-dialog
    v-if="showHistoryDialog"
    @closeModal="showHistoryDialog = false"
    :currentWorkspaceUUID="bltWorkspaceDetail.workspaceUUID"
    :currentWorkspace="bltWorkspaceDetail"
    :showDialog="showHistoryDialog" />
</template>
<style scoped>
.column {
  float: left;
  width: 50%;
  padding-left: var(--jh-space-600);
  padding-right: var(--jh-space-600);
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.bold-text {
  font-weight: 500;
}
.row-space {
  padding-left: var(--jh-space-500);
}
.secondary-value {
  color: #2A2C2E;
  padding-top: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.text-link{
  border-bottom: 1px solid var(--jha-text-dark);
  font-weight: 400;
}
.icon-right-space {
  padding-right: 20px;
  vertical-align: middle;
  fill: var(--body-text-secondary-color);
}
.collapsible-address {
  --jha-collapsible-section-header-margin: 0;
  --jha-background-color: rgb(245, 245, 245);
  --jha-collapsible-section-header-padding: 0px;
}
.collapsible-address::before {
  border-bottom: 0px solid;
}
.existing-workspace-details {
  padding-top: 16px;
}
.ml-44 {
  margin-left: 44px;
}
.phoneTitle {
  text-transform: capitalize;
}
@media (max-width: 468px) {
  .ml-44 {
  margin-left: 0px;
}

}

</style>

<template>
  <form name="EnrollmentCIP">
    <div class="cip-ques">
      <div class="enroll-cip" v-for="(previousQuestion, index) in previousQuestions" :key="index">
        <div class="cip-question">Question {{ index + 1 }}: {{ previousQuestion.questionText }}</div>
        <div class="question-bank ques-ans" v-if="previousQuestion.questionType === QuestionType.SINGLE_CHOICE">
          <div class="cip-answer" v-for="(choice, index) in previousQuestion.choices" :key="index">
            <jha-icon-checkmark class="choice-icon secondary-fill-color" v-if="choice.choiceId == choice.answer" />
            <span class="choice-text" :class="{ 'selected-answer': choice.choiceId == choice.answer }">
              {{ choice.choiceText }}
            </span>
          </div>
        </div>

        <div class="question-bank ques-ans" v-if="previousQuestion.questionType === QuestionType.MULTI_CHOICE">
          <div class="cip-answer" v-for="(choice, index) in previousQuestion.choices" :key="index">
            <jha-flex-wrapper class="spacing-small">
              <jha-icon-checkmark class="choice-icon secondary-fill-color" v-if="choice.choiceId == choice.answer" />
              <span class="choice-text" :class="{ 'selected-answer': choice.choiceId == choice.answer }">
                {{ choice.choiceText }}
              </span>
            </jha-flex-wrapper>
          </div>
        </div>
        <div
          class="question-bank ques-ans"
          v-if="
            previousQuestion.questionType === QuestionType.TEXT ||
            previousQuestion.questionType === QuestionType.NUMBER ||
            previousQuestion.questionType === QuestionType.DATE ||
            previousQuestion.questionType === QuestionType.MONEY
          "
        >
          <div class="cip-questions cip-dropdown-ans">
            <span class="dollar-sign" v-if="previousQuestion.questionType === QuestionType.MONEY">$ </span>
            <span class="answer"> {{ previousQuestion.answer }}</span>
          </div>
        </div>

        <div
          class="question-bank ques-ans"
          v-if="
            previousQuestion.questionType === QuestionType.MULTI_CHOICE_LOOKUP ||
            previousQuestion.questionType === QuestionType.SINGLE_CHOICE_LOOKUP
          "
        >
          <div class="cip-questions cip-dropdown-ans">
            <span class="answer"> {{ getLookupSelectedAnswers(previousQuestion.choices) }}</span>
          </div>
        </div>

        <div class="edit-answer open mb-16">
          <jha-button
            sync
            link
            class="open"
            @click="editPreviousQuestion(previousQuestion, index)"
            v-if="cipData.status !== 'COMPLETED'"
          >
            Edit this answer
          </jha-button>
        </div>

        <div class="divider"></div>
      </div>

      <div class="enroll-cip" id="cdd-question-container" v-if="currentQuestion.questionType">
        <div class="cip-question" id="current-cdd-question" v-if="cipData.status === StatusType.INPROGRESS">
          Question {{ previousQuestions.length + 1 }}: {{ currentQuestion.questionText }}
        </div>
        <div
          class="question-bank"
          id="current-cdd-question"
          v-if="currentQuestion.questionType === QuestionType.SINGLE_CHOICE"
        >
          <div class="cip-questions" v-for="(choice, index) in currentQuestion.choices" :key="index">
            <jha-form-radio-button @checked="inputChecked" name="single_select" :value="choice" :key="choice"
              >{{ choice?.choiceText }}
            </jha-form-radio-button>
          </div>
        </div>

        <div class="question-bank" v-if="currentQuestion.questionType === QuestionType.MULTI_CHOICE">
          <div class="cip-questions" v-for="(choice, index) in currentQuestion.choices" :key="index">
            <jha-flex-wrapper class="spacing-small">
              <jha-form-checkbox
                :key="choice.choiceId"
                :value="choice.choiceId"
                @change="multiCheckboxSelect($event.detail, choice)"
              >
                {{ choice?.choiceText }}
              </jha-form-checkbox>
            </jha-flex-wrapper>
          </div>
        </div>
        <div class="question-bank" v-if="currentQuestion.questionType === QuestionType.TEXT">
          <div class="cip-questions cip-dropdown">
            <jha-form-text-input v-model="currentQuestion.selectedAnswer" outline label="Answer"> </jha-form-text-input>
          </div>
        </div>
        <div class="question-bank" v-if="currentQuestion.questionType === QuestionType.NUMBER">
          <div class="cip-questions cip-dropdown">
            <jha-form-text-input
              v-model="currentQuestion.selectedAnswer"
              @change="checkInputValidation(currentQuestion)"
              :warning="InvalidErrorMessage"
              outline
              label="Answer"
            >
            </jha-form-text-input>
          </div>
        </div>
        <div class="question-bank" v-if="currentQuestion.questionType === QuestionType.MONEY">
          <div class="cip-questions cip-dropdown">
            <jha-form-currency-input v-model="currentQuestion.selectedAnswer" outline></jha-form-currency-input>
          </div>
        </div>

        <div class="question-bank" v-if="currentQuestion.questionType === QuestionType.DATE">
          <div class="cip-questions cip-dropdown">
            <jha-form-date-input-icon
              outline
              required
              name="date"
              ref="date"
              v-model="currentQuestion.selectedAnswer"
            ></jha-form-date-input-icon>
          </div>
        </div>

        <div class="question-bank" v-if="currentQuestion.questionType === QuestionType.MULTI_CHOICE_LOOKUP">
          <div class="cip-questions cip-dropdown">
            <blt-lookup
              :lookup-url="currentQuestion.categoryUrl"
              :is-multi-choice="true"
              input-name="multiLookup"
              @onLookupChange="(response) => onLookupChange(response, currentQuestion)"
              :selected-lookup="currentQuestion.selectedAnswer"
            ></blt-lookup>
          </div>
        </div>
        <div class="question-bank" v-if="currentQuestion.questionType === QuestionType.SINGLE_CHOICE_LOOKUP">
          <div class="cip-questions">
            <blt-lookup
              :lookup-url="currentQuestion.categoryUrl"
              :is-multi-choice="false"
              input-name="multiLookup"
              @onLookupChange="(event) => onLookupChange(event, currentQuestion)"
              :selected-lookup="currentQuestion.selectedAnswer"
            ></blt-lookup>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="right-align pr-24">
      <jha-button @click="next" sync :disabled="!enableNextQuestion" v-if="cipData.status === StatusType.INPROGRESS"
        >Next question
      </jha-button>
    </div>
    <div class="pr-24">
      <jha-button block v-if="cipData.status === StatusType.ANSWERED" @click="scoreCdd" sync
        >Submit questionnaire</jha-button
      >
    </div>
  </form>
  <blt-modal-dialog
    type="small-dialog"
    :clickOutsideToClose="true"
    :showDialog="showDialog"
    @cancel="showDialog = false"
    :title="DialogConstant.EDIT_QUESTION_DIALOG_TITLE"
  >
    <template #dialog-content>
      <div class="dialog-content">
        {{ DialogConstant.EDIT_QUESTION_DIALOG_BODY }}
      </div>
    </template>
    <template #dialog-footer>
      <jha-button appearance="primary" @click="showDialog = false" class="btn-gap">Never mind</jha-button>
      <jha-button default appearance="secondary" @click="onConfirmEditQuestion">Yes, proceed</jha-button>
    </template>
  </blt-modal-dialog>
</template>

<script lang="ts">
import { $inj } from "@/common/decorators/depinject";
import REGEX from "@/common/enums/regexEnum";
import {
  ApplicantFactory,
  BltQuestionnaireFactory,
  BroadcastService,
  EnrollmentCddFactory,
  ScrollToService,
  WorkflowFactory
} from "@/common/services/services.module";
import { defineComponent } from "vue";
import { StatusType, QuestionType } from "@/common/constant/BltQuestionnaireConstant";
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import { DialogConstant } from "@/common/constant/DialogConstant";
import "@banno/jha-wc/src/forms/jha-form-radio-button/jha-form-radio-button.js";
import "@banno/jha-wc/src/forms/jha-form-text-input/jha-form-text-input.js";
import "@banno/jha-wc/src/forms/jha-form-checkbox/jha-form-checkbox.js";
import "@banno/jha-wc/src/forms/jha-form-currency-input/jha-form-currency-input.js";
import "@banno/jha-wc/src/forms/jha-form-date-input-icon/jha-form-date-input-icon.js";
import BltLookup from "@/common/components/bltLookup/bltLookup.vue";
import "@banno/jha-wc/src/icons/jha-icon-checkmark.js";
import { find, map } from "lodash";
import type { Choice, Cip, Question, Questionnaire } from "@/common/components/bltQuestionnaire/cipTypes";
import { OpenLanguageKeysConstant } from "@/common/constant/OpenLanguageKeysConstant";

export default defineComponent({
  name: "bltQuesstionnaire",
  emits: ["successCallback", "errorCallback", "dataLoaded"],
  components: {
    BltLookup,
  },
  props: {
    requestId: {
      type: Number,
      required: true
    },
    enrollmentId: {
      type: Number,
      required: true
    },
    uuid: {
      type: String,
      required: true
    },
    cipId: {
      type: Number,
      required: true
    },
    cip: {
      type: Object
    },
    outerControls: {
      type: Boolean
    },
    applicantId: {
      type: Number,
      required: true
    },
    scrollId: {
      type: String,
      default: ""
    }
  },
  setup() {
    const enrollmentCddFactory = $inj(EnrollmentCddFactory);
    const applicantFactory = $inj(ApplicantFactory);
    const scrollToService = $inj(ScrollToService);
    const bltQuestionnaireFactory = $inj(BltQuestionnaireFactory);
    const broadcastService = $inj(BroadcastService);
    const workflowFactory = $inj(WorkflowFactory);
    return {
      enrollmentCddFactory,
      applicantFactory,
      scrollToService,
      bltQuestionnaireFactory,
      broadcastService,
      workflowFactory,
    };
  },
  created() {
    this.broadcastService.on(BroadcastConstant.CIP_SUBMIT, () => {
      this.submit();
    });
    this.workflowFactory.setLoadingStatus(true);
    this.load().then(() => {
      this.workflowFactory.setLoadingStatus(false);
    });
  },
  data() {
    return {
      integerPattern: REGEX.POSITIVE_INTEGER,
      currencyPattern: REGEX.CURRENCY,
      loading: true,
      answeringQuestion: false,
      loadingSummary: false,
      scoring: false,
      isViewingSummary: false,
      previousQuestions: [] as Question[],
      questionnaire: {} as Questionnaire,
      currentQuestion: {} as Question,
      person: {},
      cipData: this.cip as Cip,
      StatusType,
      QuestionType,
      showDialog: false,
      DialogConstant,
      editQuestion: {
        question: {} as Question,
        index: 0
      },
      InvalidErrorMessage: ""
    };
  },
  methods: {
    checkInputValidation(currentQuestion: Question) {
      this.InvalidErrorMessage = this.integerPattern.test(currentQuestion.selectedAnswer)
        ? ""
        : OpenLanguageKeysConstant.NUMBER_VALIDATION_MESSAGE;
    },

    onLookupChange(response: any, currentQuestion: Question) {
      currentQuestion.selectedAnswer = response;
    },

    editPreviousQuestion(question: Question, index: number) {
      this.showDialog = true;
      this.editQuestion.question = question;
      this.editQuestion.index = index;
    },

    getLookupSelectedAnswers(choices: { choiceText: string }[]) {
      return map(choices, function (item) {
        return item.choiceText;
      }).join(", ");
    },
    submit() {
      switch (this.cipData?.status) {
        case StatusType.INPROGRESS:
          this.next();
          break;
        case StatusType.ANSWERED:
          this.scoreCdd();
          break;
      }
    },

    multiCheckboxSelect(detail: { checked: boolean }, choice: Choice) {
      choice.selected = detail.checked;
    },

    inputChecked(evt: { detail: { value: { choiceId: number } } }) {
      this.currentQuestion.selectedAnswer = evt.detail.value.choiceId;
    },

    next() {
      this.answeringQuestion = true;

      this.enrollmentCddFactory
        .answerQuestion(
          this.uuid,
          this.enrollmentId,
          this.cipId,
          this.questionnaire.cddRequestId,
          this.currentQuestion.componentId,
          this.bltQuestionnaireFactory.getAnswer(this.currentQuestion)
        )
        .then(
          (response: { previousQuestions: Question[]; status: string; question: Question }) => {
            this.previousQuestions.push(response.previousQuestions[0]);
            this.cipData.status = response.status;
            this.currentQuestion = response.question || {};
            this.scrollToService.scrollDivToId("cdd-question-container", "current-cdd-question");
            this.scrollToService.scrollDivToBottom(this.scrollId as string);
            this.answeringQuestion = false;
          },
          () => {
            this.answeringQuestion = false;
          }
        );
    },
    onConfirmEditQuestion() {
      this.cipData.status = StatusType.INPROGRESS;
      this.showDialog = false;
      this.previousQuestions.length = this.editQuestion.index;
      this.currentQuestion = this.editQuestion.question;
    },

    scoreCdd() {
      this.scoring = true;
      this.enrollmentCddFactory
        .scoreCdd(this.uuid, this.enrollmentId, this.cipId, this.requestId)
        .then(response => {
            this.cipData.status = StatusType.COMPLETED;
            this.loadSummary();
            this.$emit("successCallback", response.data.payload);
            this.scoring = false;
            this.broadcastService.broadcast(BroadcastConstant.CIP_COMPLETED, response.data.payload.cipDetail);
          },
          (error: Error) => {
            this.scoring = false;
            this.$emit("errorCallback", { error });
          }
        )
        .finally(() => {
          this.scoring = false;
        });
    },
    loadSummary() {
      this.loadingSummary = true;
      return this.enrollmentCddFactory
        .getSummary(this.uuid, this.enrollmentId, this.cipId, this.requestId)
        .then((response: { questions: Question[] }) => {
          this.previousQuestions = response.questions;

          this.isViewingSummary = true;
        })
        .finally(() => {
          this.loadingSummary = false;
          this.$emit("dataLoaded");
          this.scrollToService.scrollDivToBottom(this.scrollId);
        });
    },
    load() {
      this.applicantFactory.getApplicantPerson(this.uuid, this.applicantId).then((response) => {
        this.person = response;
      });
      if (this.cipData.status === StatusType.COMPLETED) {
        return this.loadSummary();
        //this.loading = false;
        // return Promise.resolve();
      }
      this.loading = true;
      return this.enrollmentCddFactory
        .beginCdd(this.uuid, this.enrollmentId, this.cipId)
        .then((response: Questionnaire) => {
          this.questionnaire = response;
          this.cipData.status = response.status;
          this.previousQuestions = response.previousQuestions;
          this.currentQuestion = this.questionnaire.question;
        })
        .finally(() => {
          this.loading = false;
          this.$emit("dataLoaded");
          this.scrollToService.scrollDivToBottom(this.scrollId);
        });
    }
  },
  computed: {
    enableNextQuestion() {
      return this.currentQuestion.selectedAnswer || !!find(this.currentQuestion.choices, { selected: true });
    }
  }
});
</script>

<style scoped>
.enroll-cip-heading {
  background: var(--jha-background-color, #edeeef);
  padding: var(--jh-space-200);
  margin-bottom: var(--jh-space-400);
  margin-right: var(--jh-space-600);
}
.dialog-content {
  padding: var(--jh-space-500);
}
.spacing-small {
  padding-bottom: var(--jh-space-50);
}

.enroll-cip {
  padding-bottom: var(--jh-space-600);
  --jha-select-text-size: 14px;
  --jha-select-text: var(--body-text-secondary-color);
  /* padding-right: var(--jh-space-600); */
}
.enroll-cip:last-child {
  padding-bottom: var(--jh-space-600);
}
.question-bank {
  padding-bottom: var(--jh-space-400);
}
.ques-ans {
  padding-bottom: var(--jh-space-200);
}

.cip-answer {
  padding-top: var(--jh-space-200);
  padding-right: var(--jh-space-600);
  padding-left: var(--jh-space-500);
}
.cip-question {
  padding-right: var(--jh-space-600);
}
.cip-questions {
  padding-top: var(--jh-space-200);
  padding-right: var(--jh-space-600);
}
.cip-questions:first-child {
  padding-top: var(--jh-space-400);
}

.cip-dropdown {
  margin-bottom: var(--jh-space-300);
  padding-top: 4px !important;
}
.cip-dropdown-ans {
  padding-top: var(--jh-space-200) !important;
}
.cip-ques {
  min-height: 100px;
}

.edit-answer {
  padding: 0px;
  cursor: pointer;
}
.btn-gap {
  margin-right: var(--jh-space-300);
}
.enroll-cip-head-content {
  padding-left: var(--jh-space-400);
}
.answer,
.dollar-sign {
  color: var(--body-text-secondary-color);
}

.content-padding {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: var(--jh-space-400);
  padding-top: var(--jh-space-100);
}
.choice-icon {
  vertical-align: bottom;
  margin-left: -24px;
}
.jh-icon-pos {
  vertical-align: middle;
  margin-top: -24px;
}
.current-app {
  padding-left: var(--jh-space-1000);
}
.divider {
  margin-bottom: 0px !important;
  /* padding-top: var(--jh-space-200) !important; */
}
div > jha-button {
  --jha-button-font-weight: 500;
}
.choice-text {
  padding-left: var(--jh-space-200);
}
:deep(.footer-content) {
  padding-top: 0;
}
@media (max-width: 599px) {
  .cip-questions, .cip-question {
      padding-right: var(--jh-space-400);
    }
  .cip-answer {
    padding-right: var(--jh-space-400);
  }
}
</style>

import {inject, injectable} from 'inversify'
import {SingletonService} from '@/common/dependency.configs'
import HttpHandler from '@/common/services/connect/HttpHandler'

const SECOND = 1000

export const PING_THRESHOLD = 20 * SECOND
export const RETRY_DELAY = 5 * SECOND
export const SUPPORTED_EVENTS = ["pointerdown", "pointerup", "pointermove", "keydown", "keyup", "focus", "scroll"]

@injectable()
@SingletonService
export default class ActiveUserKeepAliveService {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
  ) {}

  private lastSuccessfulPingTime = Date.now()
  private listening = false
  private pinging = false

  private handleActivity = () => {

    const now = Date.now()
    const timeSinceLastPing = now - this.lastSuccessfulPingTime

    if(timeSinceLastPing > PING_THRESHOLD && !this.pinging) {
      this.pinging = true
      this.httpHandler.get("/v1/ping")
        .then(() => {
          this.lastSuccessfulPingTime = Date.now()
        })
        .catch(() => {
          console.error("Error communicating with server trying to send keepalive ping, retrying...")
          // To avoid spamming the server with requests, we'll wait 5 seconds before retrying on things like temporary 404s.
          setTimeout(() =>{
            this.handleActivity()
          }, RETRY_DELAY)
        })
        .finally(() => {
          this.pinging = false;
        })
    }

  }

  startListening() {
    if(this.listening) {
      console.warn("Attempted to start listening for user activity when already started.")
      return
    }
    console.info("Started listening for user activity...")
    this.listening = true
    SUPPORTED_EVENTS.forEach((event) => window.addEventListener(event, this.handleActivity))
  }

  stopListening() {
    if(!this.listening) {
      console.warn("Attempted to stop listening for user activity when already stopped.")
      return
    }
    console.info("Stopped listening for user activity...")
    SUPPORTED_EVENTS.forEach((event) => window.removeEventListener(event, this.handleActivity))
    this.listening = false
  }

}


export interface LegacyModule {
  name: string,
  path: string,
}
export const legacyManagementModules: LegacyModule[] = [
  {
    name: "Rules",
    path: "/rules"
  }
];
const STATE_MSG = Object.freeze({
  BRANCH_LOCATION: {
    BRANCH_SELECTION: "BRANCH_SELECTION",
    SEARCH_PLACEHOLDER: "Search branch locations",
    EMPTY_MESSAGE: "There are no locations that match your search parameters",
    RESIZE_BY: "resize",
    JHA_FORM_SEARCH_INPUT: "jha-form-search-input",
    INPUT: "input",
    END: "end",
    SMOOTH: "smooth",
    BRANCH_LOCATION_WRAP: "branchLocationWrap",
    TRANSITIONEND: "transitionend"
  },
  INITIAL_DISCLOSURE: {
    INITIAL_DISCLOSURE: "INITIAL_DISCLOSURE",
    EMPTY_MESSAGE: "There are no disclosures to review at this time.",
    DISCLOSURE_TITLE: "Disclosures",
    COMPLETE: "COMPLETE",
    BUTTON_CONTINUE: "Continue",
    ESIGNATURE: "ESIGNATURE",
    PROVIDED: "PROVIDED",
    REVISION: "REVISION",
    ACCEPTED: "ACCEPTED",
    DECLINED: "DECLINED",
    TIMEOUT: "timeout",
    TOAST_ERROR_MESSAGE: "There was an error loading the disclosure package."
  },
  FINAL_DISCLOSURE: {
    FINAL_DISCLOSURE: "FINAL_DISCLOSURE",
    EMPTY_MESSAGE: "No disclosures available at this time",
    DISCLOSURE_TITLE: "Disclosures",
  },
  WALL: {
    ORIGINATOR_TITLE: "Waiting for invited applicant(s)",
    INVITEE_TITLE: "Applicant information complete",
    CONTINUE_MSG: "Click 'Continue' to continue your application.",
    CONTINUE_TITLE: "Invited applicants have completed their portion"
  },
  SERVICES: {
    SAVE: "Save",
    SERVICES: "SERVICES",
    EMPTY_DEBIT_CARD: "There are no debit cards added",
    ADD_NEW_DEBIT_CARD: "Add a new debit card",
    NO_IMAGE: "No image",
    CHOOSE_CARD_IMAGE: "Choose card image",
    CHANGE_CARD_IMAGE: "Change card image",
    PRIMARY: "Primary",
    EDIT_ACCOUNTS: "Edit account(s)",
    EMPTY_OVERDRAFT_TRANSFER: "There are no overdraft transfers set up",
    NEW_ADD_ONVERDRAFTTRANSFER: "Add a new overdraft transfer",
    EDIT_OVERDRAFT_TRANSFER: "Edit transfer account(s)",
    TRANSFER_ACCOUNTS: "Transfer accounts:",
    SELECT_DEBIT_CARD: "Select a debit card:",
    SELECT_ACCOUNTS: "Select accounts",
    SET_PRIMARY: "Set as Primary",
    SELECT_DEBITCARD_ACCOUNTS: "Select an account or accounts to use with this debit card.",
    SELECT_OVERDRFT_TRANSFER_TITLE:
      "Select your overdraft transfer source(s) for Basic Checking Account. You can drag to re-order your transfersources if you have multiple.",
    SELECT_OVERDRFT_TRANSFER_TITLE_WITHOUT_REORDER: "Select your overdraft transfer source(s) for",
    SELECT_OVERDRFT_TRANSFER_ACCOUNT_TITLE: "Select an account to set up an overdraft transfer for:",
    OVERDRAFT_TRANSFER_TITLE: "Overdraft transfer"
  },
  ACCOUNT_APPLICANT_DISCLOSURE: {
    ACCOUNT_APPLICANT_DISCLOSURE: "ACCOUNT_APPLICANT_DISCLOSURE",
    EMPTY_MESSAGE: "No disclosures available at this time",
    DISCLOSURE_TITLE: "Disclosures",
  },
  ADDRESS: {
    INVALID_ZIP_CODE: "Invalid zip code"
  },
  EMAIL: {
    EMAIL_NOT_VALID: "This email is invalid.",
    EMAIL_REQUIRED: "This is a required field."
  },
  WORKFLOW_STATE: {
    title: "Errors on Form",
    content: "If you continue, changes will be lost."
  },
  EMAIL_DISCLOSURE_DIALOG: {
    EMAIL_NOT_VALID: "Must be a valid Email Address.",
    EMAIL_REQUIRED: "This is a required field.",
    EMAIL_NOTE: "Enter the email address to send the disclosures to."
  },
  RIGHT_OF_SURVIVORSHIP: {
    ROS_QUESTION_DESCRIPTION:
      "Adding Right of Survivorship to your joint account means that if an account holder dies, the funds in the account will belong to the surviving joint account holder."
  },
  EMP_DISCLOSURE: {
    EMAIL_BUTTON: "Send",
    PRINT_BUTTON: "Print",
    EMAIL: "EMAIL",
    LIST: "LIST",
    PRINT: "PRINT",
    ESIGNATURE: "ESIGNATURE",
    SELECT_APPLICANT: "SELECT_APPLICANT"
  },
  PERSON_INFO: {
    CURRENT_WORKSPACE_SSN_DUPLICATE_ERROR:
      "The entered Tax ID is currently is use by another person in the current workspace.",
    CORE_SSN_DUPLICATE_ERROR: "The entered Tax ID is currently is use by another person in the core."
  },
  EMP_SERVICES: {
    SAVE: "Save",
    SERVICES: "SERVICES",
    EMPTY_DEBIT_CARD: "There are no debit cards added",
    ADD_NEW_DEBIT_CARD: "Add a new debit card",
    NO_IMAGE: "No image",
    CHOOSE_CARD_IMAGE: "Choose card image",
    CHANGE_CARD_IMAGE: "Change card image",
    PRIMARY: "Primary",
    EDIT_ACCOUNTS: "Edit account(s)",
    EMPTY_OVERDRAFT_TRANSFER: "There are no overdraft transfers set up",
    NEW_ADD_ONVERDRAFTTRANSFER: "Add a new overdraft transfer",
    EDIT_OVERDRAFT_TRANSFER: "Edit transfer account(s)",
    TRANSFER_ACCOUNTS: "Transfer accounts:",
    SELECT_DEBIT_CARD: "Select a debit card",
    SELECT_DEBIT_CARD_DESCRIPTION: "Select a debit card:",
    SELECT_ACCOUNTS: "Select accounts",
    SET_PRIMARY: "Set as Primary",
    SELECT_DEBITCARD_ACCOUNTS: "Select an account or accounts to use with this debit card.",
    SELECT_OVERDRFT_TRANSFER_TITLE:
      "Select your overdraft transfer source(s) for Basic Checking Account. You can drag to re-order your transfersources if you have multiple.",
    SELECT_OVERDRFT_TRANSFER_TITLE_WITHOUT_REORDER: "Select your overdraft transfer source(s) for",
    SELECT_OVERDRFT_TRANSFER_ACCOUNT_TITLE: "Select an account to set up an overdraft transfer for:",
    OVERDRAFT_TRANSFER_TITLE: "Overdraft transfer",
    NO_PRODUCT_IMAGE_FOUND: "There are no debit card images available for this product.",
    CARD_IMAGE_TITLE: "Choose a debit card image",
    SELECT_CARD_IMAGE_DESC: 'Select a card image:',
    PRODUDCT_SAVE_BUTTON: 'Save'
  },
  EMP_CIP: {
    DEFAULT_COLOR: "DEFAULT",
    SUCCESS_COLOR: "SUCCESS",
    ERROR_COLOR: "ERROR",
    WARNING_COLOR: "WARNING",
    REMOVE_CONFIRM_DIALOG: {
      title_pre_text: "Remove applicant from accounts?",
      content: "Would you like to remove",
      content_additional: "from all accounts? The workspace cannot proceed with them if they fail",
      continue: "Yes, proceed",
      cancel: "Cancel",
    },
    RE_RUN_DIALOG: {
      title_pre_text: "Re-run ",
      title_post_text: "?",
      content: "This current CIP service will be re-run, are you sure you'd like to continue?",
      continue: "Yes, proceed",
      cancel: "Cancel"
    },
    NO_VERIFICATION_SERVICES_ENABLED_OR_AVAILABLE: 'No verification services are currently enabled or available',
    OVERRIDE: {
      DESCRIPTION: "Please select an override decision.",
      LABEL: "Override Decision",
      ACCEPT: "ACCEPT",
      DENY: "REJECT",
      VALIDATE_LABEL: "Validate",
      DENY_LABEL: "Deny",
      VALIDATE: "This is a required field.",
      INFO: "Please add comments describing why you have chosen this override decision.",
      COMMENTS_LABEL: "Comments"
    }
  },
  EMP_PERSON_REALTIONSHIP: {
    INCOMPLETE_STATE: "Incomplete",
    COMPLETE_STATE: "Complete",
    PERSON_RELATIONSHIP: "PERSON_RELATIONSHIP",
    RELATIONSHIP_WITH: "Relationship to",
    SELECT_RELATIONSHIP: "Select Relationship",
    NO_RELATIONSHIP_STATE: "No relationship information is required at this time"
  },
  EMP_BUSINESS_INFO: {
    BUSINESS_NAME: "businessName",
    DOING_BUSINESS_AS: "doingBusinessAs",
    BUSINESS_TYPE: "businessType",
    TAX_ID_TYPE: "taxIdType",
    EIN: " EIN",
    SSN: "SSN",
    TAX_ID: "taxId",
    NAICS_CODE: "naicsCode",
    FOR_PROFIT: "forProfit",
    DOE: "dateOfEstablishment",
    STATE_OF_LEGAL_FORMATION: "stateOfEstablishment",
    COUNTRY_OF_LEGAL_FORMATION: "countryOfEstablishment",
    EIN_PATTERN: "d{2}[-]d{7}"
  },
  EMP_DOCUMENTS: {
    EMPTY_STATE_MESSAGE: "There are no documents required for this applicant."
  }
});

export default STATE_MSG;

import {inject, injectable} from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type {OwnershipFieldsVisibility} from "@/common/data/OwnershipFields";

@injectable()
class OwnershipFieldsFactory {constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

    getOwnershipFields(workspaceUUID: string, businessApplicantId: number): Promise<OwnershipFieldsVisibility> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/business-ownership-questions`,
            {},
            ResponseTypes.Payload
        );
    }
}

export default OwnershipFieldsFactory;
import {isUndefined} from "lodash";
import LogService from "@/common/services/Log/LogService";
import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { PlaidConstats } from "@/common/constant/PlaidConstants";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type { IPlaidStore } from "@/common/services/Vendor/Plaid/IPlaidStore";
import type {PlaidItem} from "@/common/data/PlaidAccount";

@injectable()
class PlaidFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LogService) private logService: LogService,
    @inject(DEPENDENCYTYPES.IPlaidStore) private plaidStore: IPlaidStore
  ) {}

  async getConfig(workspaceUUID: string, enrollmentId: number, applicantId: number, plaidAccountId?: string) {
    const response = await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/plaid/config`,
      {
        params: {
          accountId: plaidAccountId,
          redirectUri: encodeURI(window.location.origin)
        }
      },
      ResponseTypes.Payload
    );
    this.plaidStore.plaidLinkToken = response.token;
    this.plaidStore.plaidWorkspaceUuid = workspaceUUID;
    return response;
  }

  openPlaidValidationModal(
    workspaceUUID: string,
    enrollmentId: number,
    applicantId: number,
    plaidAccount: { account: { account_id: string } }
  ) {
    return this.getConfig(workspaceUUID, enrollmentId, applicantId, plaidAccount.account.account_id).then((config) => {
      return this.link(config).then((plaidResponse) => {
        return this.verifyPlaidAccounts(workspaceUUID, plaidResponse);
      });
    });
  }

  verifyPlaidAccounts(workspaceUUID: string, plaidResponse: { metaData: { accounts: any[] } }) {
    const plaidAccountIds = plaidResponse.metaData.accounts.reduce(
      (acc: number[], cur: { verification_status: string; id: number }) => {
        if (cur.verification_status === PlaidConstats.MANUALLY_VERIFIED) {
          acc.push(cur.id);
        }
        return acc;
      },
      []
    );

    return Promise.all(
      plaidAccountIds.map(async (plaidAccountId: number) => {
        return await this.httpHandler.post(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/plaid/ach`,
          { accountId: plaidAccountId },
          undefined,
          ResponseTypes.Payload
        );
      })
    );
  }

  async sendAutomaticMicrodepositACH(
    workspaceUUID: string,
    account: { verificationStatus: string; account_id: string }
  ) {
    return account.verificationStatus === PlaidConstats.AUTOMATICALLY_VERIFIED
      ? await this.httpHandler.post(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/plaid/ach`,
          {
            accountId: account.account_id
          },
          undefined,
          ResponseTypes.Payload
        )
      : Promise.reject();
  }

  async postAccounts(
    workspaceUUID: string,
    enrollmentId: number,
    applicantId: number,
    token: string,
    plaidAccounts: any[]
  ) {
    // https://plaid.com/docs/#account-types
    // Only POST Checking or Savings account.
    plaidAccounts = plaidAccounts.reduce((acc: number[], cur: { type: string; id: number }) => {
      if (cur.type === PlaidConstats.DEPOSITORY) acc.push(cur.id);
      return acc;
    }, []);

    return plaidAccounts.length
      ? await this.httpHandler.post(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/plaid/token`,
          {
            publicToken: token,
            accountIds: plaidAccounts
          },
          undefined,
          ResponseTypes.Payload
        )
      : Promise.resolve({});
  }

  async addLinkedAccounts(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    return this.getConfig(workspaceUUID, enrollmentId, applicantId)
      .then((plaidConfig) => {
        return this.link(plaidConfig);
      })
      .then((plaidResponse) => {
        return plaidResponse.metaData.accounts
          ? this.postAccounts(
              workspaceUUID,
              enrollmentId,
              applicantId,
              plaidResponse.publicToken,
              plaidResponse.metaData.accounts
            )
          : Promise.resolve();
      });
  }

  async addLinkedAccountsFromOauth(plaidConfig: any, workspaceUUID: string, enrollmentId: number, applicantId: number) {
    const plaidResponse = await this.link(plaidConfig);
    return plaidResponse.metaData.accounts
      ? this.postAccounts(
          workspaceUUID,
          enrollmentId,
          applicantId,
          plaidResponse.publicToken,
          plaidResponse.metaData.accounts
        )
      : Promise.resolve();
  }

  async getWorkspaceInfoFromToken(workspaceUuid: string, token: string) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUuid}/plaid/config/${token}`,
      undefined,
      ResponseTypes.Payload
    );
  }

  getPlaidModule(): any {
    return (<any>window).Plaid;
  }

  logError(deferred: PromiseConstructor): any {
    this.logService.error(PlaidConstats.PLAID_ERROR_MESSAGE);
    deferred.reject(PlaidConstats.PLAID_ERROR_MESSAGE);
  }
  link(plaidConfig: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const plaidModule = this.getPlaidModule();
      let plaid;

      if (isUndefined(plaidModule)) {
        this.logError(Promise);
      } else {
        plaid = plaidModule.create({
          token: plaidConfig.token,
          isWebview: true,
          receivedRedirectUri: plaidConfig.receivedRedirectUri,
          onSuccess: (publicToken: string, metaData: any) => {
            resolve({
              publicToken: publicToken,
              metaData: metaData
            });
          },
          onExit: () => reject(),
          onEvent: (metaData: { error_code: string }) => {
            if (metaData.error_code === PlaidConstats.TOO_MANY_VERIFICATION_ATTEMPTS) {
              reject(metaData);
            }
          }
        });
        plaid.open();
      }
    });
  }

  async getPlaidAccounts(workspaceUUID: string, enrollmentId: number, applicantId: number): Promise<Array<PlaidItem>> {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/plaid/account`,
      undefined,
      ResponseTypes.Payload
    );
  }

  async syncPlaidAccounts(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/plaid/account/sync`,
      {},
      undefined,
      ResponseTypes.Payload
    );
  }

  async syncThenGetAccounts(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    await this.syncPlaidAccounts(workspaceUUID, enrollmentId, applicantId);
    return this.getPlaidAccounts(workspaceUUID, enrollmentId, applicantId);
  }
}
export default PlaidFactory;

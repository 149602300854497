import {inject, injectable} from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type ApplicantPowers from "@/common/data/ApplicantPowers";
import type BusinessApplicantPower from "@/common/data/BusinessApplicantPower";

@injectable()
class BusinessApplicantPowersService {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {
    }

    getPowersList(
        workspaceUUID: string,
        businessApplicantId: number,
        childApplicantId: number
    ): Promise<Array<ApplicantPowers>> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${childApplicantId}/business-powers-fields`,
            {},
            ResponseTypes.Payload
        )
    }

    get(
        workspaceUUID: string,
        businessApplicantId: number,
        applicantId: number
    ): Promise<Array<BusinessApplicantPower>> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}/power`,
            {},
            ResponseTypes.Payload
        )
    }

    delete(
        workspaceUUID: string,
        businessApplicantId: number,
        applicantId: number,
        DTO: BusinessApplicantPower
    ): Promise<void> {
        return this.httpHandler.delete(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}/power`,
            {
                data: DTO
            },
            ResponseTypes.Payload
        )
    }


    save(
        workspaceUUID: string,
        businessApplicantId: number,
        applicantId: number,
        DTO: BusinessApplicantPower
    ): Promise<BusinessApplicantPower> {
        return (DTO.powerId)
            ? this.httpHandler.put(
                `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}/power/${DTO.powerId}`,
                DTO,
                {},
                ResponseTypes.Payload
            )
            : this.httpHandler.post(
                `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}/power`,
                DTO,
                {},
                ResponseTypes.Payload
            )
    }
}

export default BusinessApplicantPowersService;
import {injectable} from 'inversify'
import {Service} from '@/common/dependency.configs'
import {createApp, h} from 'vue'
import InactivityModal from '@/open/services/modals/InactivityModal.vue'

@injectable()
@Service
export default class InactivityModalUtil {
  prompt(): Promise<void> {
    const elem = document.createElement('div')

    return new Promise((res, rej) => {
        const app = createApp(h(
          InactivityModal,
          {},
        ))

        app.mount(elem)
      }
    )
  }
}

<script lang="ts">
import '@banno/jha-wc/src/icons/jha-icon-information.js'
import '@banno/jha-wc/src/icons/jha-icon-warning.js'
import '@banno/jha-wc/src/jha-error-message/jha-error-message.js'
import '@banno/jha-wc/src/jha-message/jha-message.js'

import {defineComponent} from 'vue'
import {NotificationConstants} from '@/common/constant/NotificationConstants'
import type {IErrorMessage} from '@/common/models/IErrorMessage'

export default defineComponent({
  name: 'bltNotificationEmp',
  props: {
    messages: {type: Array<IErrorMessage>}
  },
  data() {
    return {NotificationConstants}
  },
  computed: {
    warnings() {
      return this.messages?.filter((m) => m.type === NotificationConstants.WARN)
    },
    errors() {
      return this.messages?.filter((m) => m.type === NotificationConstants.ERROR)
    },
    infos() {
      return this.messages?.filter((m) => m.type === NotificationConstants.INFO)
    }
  }
})
</script>
<template>
  <div class="blt-notification-emp-container">
    <jha-error-message
      class="error-notification blt-notification"
      v-for="(message, index) in errors"
      :key="index">
      {{ message.message }}
    </jha-error-message>

    <jha-message
      class="blt-notification"
      v-for="(message, index) in infos"
      :key="index">
      <div class="d-flex center">
        <jha-icon-information class="icon-align icon error-icon-top"></jha-icon-information>&nbsp;
        <div class="message">{{ message.message }}</div>
      </div>
    </jha-message>

    <jha-message
      class="information blt-notification"
      v-for="(message, index) in warnings"
      :key="index">
      <div class="d-flex center">
        <jha-icon-warning class="icon-align icon error-icon-top"></jha-icon-warning>&nbsp;
        <div class="message">{{ message.message }}</div>
      </div>
    </jha-message>
  </div>
</template>
<style scoped>
.blt-notification-emp-container {
  border-radius: 5px;
  overflow: hidden;
}

.blt-notification {
  border-radius: 0;
}

.error-notification {
  background-color: var(--jha-color-danger);
}
</style>

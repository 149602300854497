import HttpHandler from "@/common/services/connect/HttpHandler";
import { isObject } from "lodash";
import { inject, injectable } from 'inversify';
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type RestResponse from "@/common/data/RestResponse";
import type IDemographics from "@/common/data/IDemographics";

@injectable()
class ApplicantDemographicFactory {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

    getApplicantDemographic(uuid: string, applicantId: number): Promise<IDemographics> {
        return this.httpHandler.get(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/demographic`, {}, ResponseTypes.Payload);
    }

    formatObjectsFromAutocomplete(demographic: any) {
        Object.keys(demographic).forEach(item => {
            if (isObject(demographic[item]) && demographic[item] !== null) {
                demographic[item] = demographic[item].typeCode;
            }
        });
        return demographic;
    }

    postApplicantDemographic(uuid: string, applicantId: number, demographic: any): Promise<IDemographics> {
        const postDemographic = this.formatObjectsFromAutocomplete(demographic);
        return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/demographic`, postDemographic, {}, ResponseTypes.Payload);
    }

    putApplicantDemographic(uuid: string, applicantId: number, demographic: any): Promise<IDemographics> {
        const putDemographic = this.formatObjectsFromAutocomplete(demographic);
        return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/demographic/${demographic.demographicId}`, putDemographic, {}, ResponseTypes.Payload);
    }

    save(uuid: string, applicantId: number, demographic: IDemographics): Promise<IDemographics> {
        return demographic.demographicId
            ? this.putApplicantDemographic(uuid, applicantId, demographic)
            : this.postApplicantDemographic(uuid, applicantId, demographic)
    }

    deleteApplicantDemographic(uuid: string, applicantId: number, demographic: any) {
        return this.httpHandler.delete(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/demographic/${demographic.demographicId}`, {}, ResponseTypes.Data);
    }
}
export default ApplicantDemographicFactory;
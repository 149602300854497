<script lang="ts">
//file need to be renamed to bltForm.vue in next version
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import { LanguageFactory, NotificationFactory, WorkflowFactory } from "@/common/services/services.module";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import {ComponentPublicInstance, defineComponent} from "vue";
import {cloneDeep, isEqual} from "lodash";

export default defineComponent({
  name: "bltForm",
  emits: ["continueClicked", "backClicked", "searchBtnClicked"],
  props: {
    alternateTitle: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean,
      default: () => false
    },
    //Function when clicking continue
    continue: { type: Function },
    //When Continue should be disabled
    //renamed: continueDisabled
    continueDisabled: { type: Boolean, default: () => false },
    //Back button's function
    back: { type: Function },
    //When Continue should be disabled
    continueReplacedWithSecondary: {
      type: Boolean,
      default: () => false
    },
    alternateBackEnabled: {
      type: Boolean,
      default: () => false
    },
    //Enable an alternate back text & function
    alternateBackText: {
      type: String
    },
    //Text on alternate back button
    alternateBackFunc: {
      type: Function
    },
    //Function on alternate back button
    //renamed: backDisabled
    backDisabled: {
      type: Boolean,
      default: () => false
    },
    //When Back should be disabled
    disabled: {
      type: Boolean,
      default: () => false
    },
    //Same as ContinueDisabled, but effects both back and continue
    secondaryAction: {
      type: Function
    },
    //Function when clicking the secondary button
    secondaryActionText: {
      type: String
    },
    //Secondary button text
    secondaryActionActive: {
      type: Object
    },
    //When the Secondary button should be active
    secondaryActionClass: {
      type: String
    },
    //Class(es) for the secondary button
    //renamed: secondaryDisabled
    continueDisabledSecondary: {
      type: Boolean,
      default: () => false
    },
    //Disables secondary button
    loading: {
      type: Boolean,
      default: () => false
    },
    //Disable both continue and back while this is true
    hideInfoBlock: {
      type: Boolean,
      default: () => false
    },
    //Hide the info block if this is true
    hideNavigation: {
      type: Boolean,
      default: () => false
    },
    bltUiText: {
      type: String
    },
    hideProgressbar: {
      type: Boolean,
      default: () => false
    },
    hideBackButton: {
      type: Boolean,
      default: () => false
    },
    hideContinueButton: {
      type: Boolean,
      default: () => false
    },
    showDeterminateProgressBar: {
      type: Boolean,
      default: () => false
    },
    searchIcon: {
      type: Boolean,
      default: () => false
    },
    innerScreenButtonEnabled: {
      type: Boolean,
      default: () => false
    },
    //Inner screen additional button
    innerScreenBtnFunc: {
      type: Function
    },
    contentCardClass: {
      type: String
    },
    dividerLine: {
      type: Boolean,
      default: () => false
    },
    contentMarginClass: {
      type: String
    },
    hideContinueLoader: {
      type: Boolean,
      default: () => true
    },
    staticTitle: {
      type: Boolean,
      default: () => false
    },
    contentHeightFix: {
      type: Boolean,
      default: () => false
    },
    loaderMessages: {
      type: Array,
      default: () => []
    },
    secondaryTitle: {
      type: String
    },
    hideErrorNotification: {
      type: Boolean,
      default: () => false
    },
    description: {
      type: String,
      required: false
    },
    showCurrentApplicant:{
      type: Boolean,
      default: ()=> false
    },
    currentStateCssClass:{
      type: String,
      default:()=>""
    },
    bltScreenClass: {
      type: String,
      required: false,
      default: '',
    }
  },
  setup() {
    const broadcastService = $inj(BroadcastService);
    const languageFactory = $inj(LanguageFactory);
    const workflowFactory = $inj(WorkflowFactory);
    const workspaceMetadataStore = $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore");
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    const notificationFactory = $inj(NotificationFactory);

    return {
      broadcastService,
      languageFactory,
      workflowFactory,
      workspaceMetadataStore,
      notificationFactory,
      workspaceStore
    };
  },
  data() {
    return {
      formDescription: ""
    };
  },
  created: async function () {
    this.broadcastService.on(BroadcastConstant.WIZARD_TOGGLE_SEARCH, (showSearchBar: boolean) => {
      this.$emit("searchBtnClicked", showSearchBar);
    });
    this.broadcastService.on(BroadcastConstant.WIZARD_NAVIGATED, (direction: string) => {
      if (direction === "back") {
        this.$emit("backClicked");
      } else if (direction === "continue") {
        this.$emit("continueClicked");
      }
    });
    this.broadcastService.on(
      BroadcastConstant.DISPLAY_TOAST,
      (response: {
        data: { infos: { message: string }[]; warnings: { message: string }[]; errors: { message: string }[] };
      }) => {
        this.notificationFactory.showData(response.data);
      }
    );

    this.assignWizardProps().then(() => {
      this.broadcastService.broadcast(BroadcastConstant.BLTFORM_CREATED_UPDATED, {});
    });
    this.formDescription = await this.getInfoBlock();
  },
  updated: function () {
    const wizardPropsBefore = cloneDeep(this.workspaceMetadataStore.wizardProps)
    this.assignWizardProps().then(() => {

      // Infinite loop breaker.
      if (!isEqual(wizardPropsBefore, this.workspaceMetadataStore.wizardProps)) {
        this.broadcastService.broadcast(BroadcastConstant.BLTFORM_CREATED_UPDATED, {});
      }
    });
  },
  computed: {
    currentState: function () {
      const tempcurrentState = this.workflowFactory.getState() || { nextStateId: true, description: "" };
      return tempcurrentState;
    },
    getTransitioning: function () {
      const tempgetTransitioning = this.workflowFactory.getTransitioning();
      return tempgetTransitioning;
    },
    continueButtonDisabled: function () {
      return (
        (!this.currentState.nextStateId && this.currentState.state !== WorkflowStatesConstant.DECISION.STATE) ||
        this.continueDisabledSecondary ||
        this.loading ||
        this.continueDisabled ||
        this.disabled ||
        this.getTransitioning
      );
    },
    isContentLoading() {
      return this.loading || this.workflowFactory.getTransitioning();
    },
    infoBlock() {
      if (this.description && this.description.length) {
        return this.description;
      } else {
        return this.formDescription;
      }
    },
    showActiveApplicantIfHasMoreThanOneApplicant(){
      //developer can compare to equal 1 for testing purpose. Original logic is to be greater than 1
      return this.showCurrentApplicant 
        && this.workspaceStore.applicantsCount > 1;
    },
    currentActiveApplicantId(){
      return this.workspaceStore.activeApplicantId;
    }
  },
  methods: {
    getInfoBlock() {
      if (this.currentState && this.currentState.descriptionKey) {
        return this.languageFactory.get(this.currentState.descriptionKey);
      } else {
        return this.currentState.description;
      }
    },
    async doContinue() {
      if (!this.continueButtonDisabled) {
        this.continue ? await this.continue() : await this.workflowFactory.next();
      }
      this.broadcastService.broadcast(BroadcastConstant.WIZARD_NAVIGATED, "continue");
    },
    doInnerScreenAction() {
      if (this.innerScreenBtnFunc) {
        this.innerScreenBtnFunc();
      }
    },
    doSecondaryAction(event: Event) {
      if (this.secondaryAction) {
        this.secondaryAction(event);
      }
    },
    assignWizardProps(): Promise<void> {
      return new Promise((resolve) => {
        this.workspaceMetadataStore.wizardProps.alternateBackEnabled = this.alternateBackEnabled;
        this.workspaceMetadataStore.wizardProps.alternateBackEnabled = this.alternateBackEnabled;
        this.workspaceMetadataStore.wizardProps.alternateBackEnabled = this.alternateBackEnabled;
        this.workspaceMetadataStore.wizardProps.alternateBackEnabled = this.alternateBackEnabled;
        this.workspaceMetadataStore.wizardProps.alternateBackText = this.alternateBackText;
        this.workspaceMetadataStore.wizardProps.alternateBackFunc = this.alternateBackFunc;
        this.workspaceMetadataStore.wizardProps.continueDisabled = this.continueDisabled;
        this.workspaceMetadataStore.wizardProps.continueReplacedWithSecondary = this.continueReplacedWithSecondary;
        this.workspaceMetadataStore.wizardProps.backDisabled = this.backDisabled;
        this.workspaceMetadataStore.wizardProps.disabled = this.disabled;
        this.workspaceMetadataStore.wizardProps.secondaryAction = this.secondaryAction;
        this.workspaceMetadataStore.wizardProps.secondaryActionText = this.secondaryActionText;
        this.workspaceMetadataStore.wizardProps.secondaryActionActive = this.secondaryActionActive;
        this.workspaceMetadataStore.wizardProps.secondaryActionClass = this.secondaryActionClass;
        this.workspaceMetadataStore.wizardProps.continueDisabledSecondary = this.continueDisabledSecondary;
        this.workspaceMetadataStore.wizardProps.loading = this.loading;
        this.workspaceMetadataStore.wizardProps.loaderMessages = this.loaderMessages;
        this.workspaceMetadataStore.wizardProps.hideInfoBlock = this.hideInfoBlock;
        this.workspaceMetadataStore.wizardProps.hideNavigation = this.hideNavigation;
        this.workspaceMetadataStore.wizardProps.bltUiText = this.bltUiText;
        this.workspaceMetadataStore.wizardProps.hideProgressbar = this.hideProgressbar;
        this.workspaceMetadataStore.wizardProps.hideBackButton = this.hideBackButton;
        this.workspaceMetadataStore.wizardProps.hideContinueButton = this.hideContinueButton;
        this.workspaceMetadataStore.wizardProps.showDeterminateProgressBar = this.showDeterminateProgressBar;
        this.workspaceMetadataStore.wizardProps.searchIcon = this.searchIcon;
        this.workspaceMetadataStore.wizardProps.innerScreenButtonEnabled = this.innerScreenButtonEnabled;
        this.workspaceMetadataStore.wizardProps.innerScreenBtnFunc = this.innerScreenBtnFunc;
        this.workspaceMetadataStore.wizardProps.contentCardClass = this.contentCardClass;
        this.workspaceMetadataStore.wizardProps.dividerLine = this.dividerLine;
        this.workspaceMetadataStore.wizardProps.hideTitle = this.hideTitle;
        this.workspaceMetadataStore.wizardProps.continue = this.continue;
        this.workspaceMetadataStore.wizardProps.back = this.back;
        this.workspaceMetadataStore.wizardProps.hideContinueLoader = this.hideContinueLoader;
        this.workspaceMetadataStore.wizardProps.contentMarginClass = this.contentMarginClass;
        this.workspaceMetadataStore.wizardProps.staticTitle = this.staticTitle;
        this.workspaceMetadataStore.wizardProps.secondaryTitle = this.secondaryTitle;
        this.workspaceMetadataStore.wizardProps.hideErrorNotification = this.hideErrorNotification;
        this.workspaceMetadataStore.wizardProps.alternateTitle = this.alternateTitle;

        resolve();
      });
    }
  }
});
</script>
<template>
  <blt-screen :contentHeightFix="contentHeightFix"
              :class="bltScreenClass">
    <template v-slot:content>
      <div class="card-content-animated-wrapper">
        <div class="subheader" v-if="infoBlock && !hideInfoBlock">
          <div>{{ infoBlock }}</div>
        </div>
        <div :class="currentStateCssClass">
          <div v-if="showActiveApplicantIfHasMoreThanOneApplicant" class="blt-current-applicant-wrapper">
            <blt-current-applicant
              :applicantId="currentActiveApplicantId"></blt-current-applicant>
          </div>
        </div>
        <slot>
          <!-- the workflow state screen is rendered here -->
        </slot>
      </div>
    </template>
    <template v-slot:footer>
      <div v-show="!hideContinueButton && !hideNavigation">
        <div class="divider" v-if="dividerLine"></div>
        <jha-button
          block
          :sync="hideContinueLoader"
          v-if="continueReplacedWithSecondary"
          :class="secondaryActionClass"
          :aria-label="secondaryActionText"
          @click="doSecondaryAction"
          :disabled="continueButtonDisabled"
        >
          {{ secondaryActionText }}
        </jha-button>
        <jha-button
          block
          id="buttonContinue"
          :sync="hideContinueLoader"
          v-if="!continueReplacedWithSecondary"
          :disabled="continueButtonDisabled"
          aria-label="Continue"
          @click="doContinue"
          type="submit"
          >Continue
        </jha-button>
        <jha-button
          block
          v-if="innerScreenButtonEnabled"
          :class="secondaryActionClass"
          :aria-label="secondaryActionText"
          @click="doInnerScreenAction"
          :sync="true"
          link
        >
          {{ secondaryActionText }}
        </jha-button>
      </div>
    </template>
  </blt-screen>
</template>
<style>
.hideSlot {
  display: none;
}
</style>

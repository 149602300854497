import { injectable, inject } from "inversify";
import PrintFactory from "@/common/services/utils/printFactory";
import PollingService from "@/common/services/utils/PollingService";
import ToastService from "@/common/services/toastFactory/toastFactory";
import LogService from "@/common/services/Log/LogService";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { DisclosureActionTypeConstant } from "@/common/constant/DisclosureActionTypeConstant";
import FACTORY_MSG from "@/common/messages/factory.messages";
import { DisplayConstant } from "@/common/constant/DisplayConstant";
import { ApplicantConstant } from "@/common/constant/ApplicantConstant";
import {cloneDeep} from "lodash";

@injectable()
class ApplicantDisclosureFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(PrintFactory) private printFactory: PrintFactory,
    @inject(ToastService) private toastService: ToastService,
    @inject(LogService) private logService: LogService,
    @inject(PollingService) private pollingService: PollingService
  ) {}

  async awaitInitialDisclosuresAvailable(workspaceUUID: string, applicantId: number, requestId: number) {
    return await this.pollingService
      .awaitPredicate(200, 1000 * 30, () => this.isCreated(workspaceUUID, applicantId, requestId))
      .then(() => ({ requestId }));
  }
  async isCreated(workspaceUUID: string, applicantId: number, requestId: number) {
    return this.getAllInitialDisclosureStatus(workspaceUUID, applicantId, requestId).then(
      (res: any) => res === DisplayConstant.FOUND
    );
  }
  async getAllInitialDisclosure(workspaceUUID: string, applicantId: number, requestId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/initialdisclosure-async/${requestId}`,
      {},
      ResponseTypes.Payload
    );
  }
  async getAllInitialDisclosureStatus(workspaceUUID: string, applicantId: number, requestId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/initialdisclosure-status/${requestId}`,
      {},
      ResponseTypes.Payload
    );
  }
  async postAllInitialDisclosure(workspaceUUID: string, applicantId: number) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/initialdisclosure`,
      null,
      {},
      ResponseTypes.Payload
    );
  }
  async makeApplicantDisclosurePackage(applicantId: number, applicantDisclosures: any) {
    return {
      type: ApplicantConstant.APPLICANT,
      id: applicantId,
      disclosures: applicantDisclosures || []
    };
  }
  /**
   *  This will return a disclosure package for a given applicant, this should act as a drop in replacement
   *  for getApplicantDisclosurePackage once the post / poll functionality is implemented.
   * @param workspaceUUID
   * @param applicantId
   * @return {*}
   */
  async getApplicantDisclosurePackage(workspaceUUID: string, applicantId: number) {
    return await this.postAllInitialDisclosure(workspaceUUID, applicantId)
      .then(
        async (response: any) =>
          await this.awaitInitialDisclosuresAvailable(workspaceUUID, applicantId, response.requestId)
      )
      .then(async (response: any) => await this.getAllInitialDisclosure(workspaceUUID, applicantId, response.requestId))
      .then(async (res) => await this.makeApplicantDisclosurePackage(applicantId, res));
  }
  /**
   *@ngdoc method
   * @name getApplicantDisclosure
   * @methodOf e2e.factory:this.applicantDisclosureFactory
   * @description
   * This will return a disclosure for either an applicant or an enrollment.
   *
   * @param {String} uuid - The workspace UUID the disclosure is related to.
   *
   * @param {String} applicantId - the applicantId the disclosure is related to.
   *
   * @param {String} disclosureCode - this is the disclosureCode of the desired disclosure.
   *
   */
  getApplicantDisclosure(uuid: string, applicantId: number, disclosureCode: any) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/initialdisclosure/${disclosureCode}`,
      {},
      ResponseTypes.Payload
    );
  }
  /**
   *@ngdoc method
   * @name putApplicantDisclosure
   * @methodOf e2e.factory:this.applicantDisclosureFactory
   * @description
   * This will save a disclosure to the backend.
   *
   * @param {String} uuid - The workspace UUID the disclosure is related to.
   *
   * @param {String} applicantId - the applicantId the disclosure is related to.
   *
   * @param {String} disclosure - the disclosure to be put.
   *
   */
  putApplicantDisclosure(uuid: string, applicantId: number, disclosure: any) {
    const disclosureCopy = cloneDeep(disclosure);
    delete disclosureCopy.disclosure;
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/initialdisclosure/${disclosureCopy.disclosureCode}`,
      disclosureCopy,
      {},
      ResponseTypes.Payload
    );
  }
  async hasDisclosures(uuid: string, applicantId: number) {
    return this.getApplicantDisclosurePackage(uuid, applicantId).then((response) => {
      return response && response.disclosures && response.disclosures.length > 0;
    });
  }
  /**
   *@ngdoc method
   * @name unlockApplicantDisclosure
   * @methodOf e2e.factory:this.applicantDisclosureFactory
   * @description
   * This will tell the backend to set the disclosure status to REVISION.
   *
   * @param {String} uuid - The workspace UUID the disclosure is related to.
   *
   * @param {String} applicantId - the applicantId the disclosure is related to.
   *
   * @param {String} disclosure - the disclosure to be unlocked.
   *
   */
  async unlockApplicantDisclosure(uuid: string, applicantId: number, disclosure: any) {
    delete disclosure.disclosure;
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/initialdisclosure/${disclosure.disclosureCode}/unlock`,
      disclosure,
      {},
      ResponseTypes.Payload
    );
  }
  /**
   *@ngdoc method
   * @name resetApplicantDisclosure
   * @methodOf e2e.factory:this.applicantDisclosureFactory
   * @description
   * This will tell the backend to set the disclosure status to REVISION and revert to the original dictionary
   *     data.
   *
   * @param {String} uuid - The workspace UUID the disclosure is related to.
   *
   * @param {String} applicantId - the applicantId the disclosure is related to.
   *
   * @param {String} disclosure - the disclosure to be unlocked.
   *
   */
  async resetApplicantDisclosure(uuid: string, applicantId: number, disclosure: any) {
    delete disclosure.disclosure;
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/initialdisclosure/${disclosure.disclosureCode}/unlock?reset=true`,
      disclosure,
      {},
      ResponseTypes.Payload
    );
  }
  /**
   *@ngdoc method
   * @name emailApplicantDisclosure
   * @methodOf e2e.factory:this.applicantDisclosureFactory
   * @description
   * This will send each passed disclosureStoreId as a disclosure in an email to the supplied email address.
   *
   * @param {String} uuid - The workspace UUID the disclosure is related to.
   *
   * @param {String} applicantId - the applicantId the disclosure is related to.
   *
   * @param {String} emailAddress - the email address the disclosures should be sent to.
   *
   * @param {Array} disclosures - an array of disclosures to be emailed.
   *
   */

  async emailApplicantDisclosure(uuid: string, applicantId: number, emailAddress: string, disclosures: any) {
    const storeIds = disclosures.reduce((ids: string, disclosure: { disclosureStoreId: string }) => {
      ids = ids === "" ? disclosure.disclosureStoreId : ids + "," + disclosure.disclosureStoreId;
      return ids;
    }, "");
    return await this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/disclosure/email?disclosureStoreIds=${storeIds}`,
        {
          headers: {
            emailAddress: emailAddress
          }
        },
        ResponseTypes.Data
      )
      .then(() => {
        this.toastService.success(DisclosureActionTypeConstant.DISCLOSURES_EMAILED);
      });
  }

  /**
   *@ngdoc method
   * @name printApplicantDisclosure
   * @methodOf e2e.factory:this.applicantDisclosureFactory
   * @description
   * This will open a tab and display all passed disclosures in PDF format.
   *
   * @param {String} uuid - The workspace UUID the disclosure is related to.
   *
   * @param {String} applicantId - the applicantId the disclosure is related to.
   *
   * @param {Array} disclosures - an array of disclosure objects to be printed.
   */

  async printApplicantDisclosure(uuid: string, applicantId: number, disclosures: any) {
    const storeIds = disclosures.reduce((ids: string, disclosure: { disclosureStoreId: string }) => {
      ids = ids === "" ? disclosure.disclosureStoreId : ids + "," + disclosure.disclosureStoreId;
      return ids;
    }, "");
    if (storeIds === "") {
      this.logService.error(FACTORY_MSG.APPLICANTDISCLOSURE.APPLICANT_DISCLOSURE_LOG_EROOR);
      return;
    }
    return await this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/initialdisclosure/token?disclosureStoreIds=${storeIds}`,
        {},
        ResponseTypes.Payload
      )
      .then(async (token) => {
        return await this.printFactory.printByToken(token);
      })
      .then(() => {
        this.toastService.success(DisclosureActionTypeConstant.DISCLOSURES_PRINTED);
      });
  }

  async checkEsign(uuid: string, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/esign`,
      {},
      ResponseTypes.Payload
    );
  }
  async requestEsign(uuid: string, applicantId: number, emailAddress: string) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/requestesign`,
      { emailAddress: emailAddress },
      {},
      ResponseTypes.Payload
    );
  }

  async validateEsign(uuid: string, applicantId: number, token: any) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/applicant/${applicantId}/validateesign`,
      { token: token },
      {},
      ResponseTypes.Payload
    );
  }
}
export default ApplicantDisclosureFactory;

import { isUndefined, isString, isNumber, isObject } from "lodash";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type IAccount from "@/common/models/IAccount";
import type {Product} from "@/common/models/IProductSelection";
import type {
  IAccountRightOfSurvivorship
} from "@/open/states/workflow/enrollment/info/rightOfSurvivorship/RightOfSurvivorshipState.vue";


@injectable()
class EnrollmentAccountFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAccountListPrimaryApplicantId(accountList: any[]) {
    return accountList.reduce((col, account) => {
      return account.primaryApplicantId ? account.primaryApplicantId : col;
    }, null);
  }

  getAccountListPrimaryApplicant(accountList: any[], applicantList: any[]) {
    const primaryApplicantId = this.getAccountListPrimaryApplicantId(accountList);
    if (primaryApplicantId === null) {
      return null;
    }
    return (
      applicantList.filter((applicant) => {
        return applicant.applicantId === primaryApplicantId;
      })[0] || null
    );
  }

  getEnrollmentAccountList(workspaceUUID: string, enrollmentId: number): Promise<Array<IAccount>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account`,
      {},
      ResponseTypes.Payload
    );
  }

  getEnrollmentAccount(workspaceUUID: string, enrollmentId: number, accountId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}`,
      {},
      ResponseTypes.Payload
    );
  }

  postEnrollmentAccount(
    workspaceUUID: string,
    enrollmentId: number,
    product: Product
  ): Promise<IAccount> {
    const message = {
      accountTypeId: product.productId,
      accountIntention: product.accountIntention
    };
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account`,
      message,
      {},
      ResponseTypes.Payload
    );
  }

  deleteEnrollmentAccount(workspaceUUID: string, enrollmentId: number, accountId: number) {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}`,
      {},
      ResponseTypes.Data
    );
  }

  updateRightOfSurvivorship(
    workspaceUUID: string,
    enrollmentId: number,
    account: IAccountRightOfSurvivorship,
    rightOfSurvivorship: boolean
  ) {
    account.isRightOfSurvivorship = rightOfSurvivorship;
    return this.putEnrollmentAccount(workspaceUUID, enrollmentId, account);
  }

  /*
        Value to check if the radio box should be filled on page load.

        TRUE = ALL accounts have isRightOfSurvivorship === true
        FALSE = ALL accounts have isRightOfSurvivorship === false
        else undefined
     */
  getDefaultRightOfSurvivorship(accountList: any[]) {
    if (accountList.length > 0 && this.checkAllROS(accountList, true)) {
      return true;
    } else if (accountList.length > 0 && this.checkAllROS(accountList, false)) {
      return false;
    } else {
      return undefined;
    }
  }

  checkAllROS(accountList: any[], boolean: any) {
    return accountList.every((account) => {
      return account.isRightOfSurvivorship === boolean;
    });
  }

  putEnrollmentAccount(workspaceUUID: string, enrollmentId: number, account: IAccount) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${account.accountId}`,
      account,
      {},
      ResponseTypes.Payload
    );
  }

  putEnrollmentAccountList(workspaceUUID: string, enrollmentId: number, accountList: any[]) {
    return Promise.all(
      accountList.map((account) => {
        return this.putEnrollmentAccount(workspaceUUID, enrollmentId, account);
      })
    );
  }

  setAllAccountPrimaryApplicant(uuid: string, enrollmentId: number, accountList: any[], primaryApplicantId: number) {
    return Promise.all(
      accountList.map((account) => {
        return this.setAccountPrimaryApplicant(uuid, enrollmentId, account, primaryApplicantId);
      })
    );
  }

  setAccountPrimaryApplicant(uuid: string, enrollmentId: number, account: any, primaryApplicantId: number) {
    account.primaryApplicantId = primaryApplicantId;
    return this.putEnrollmentAccount(uuid, enrollmentId, account);
  }

  accountContainsWarning(account: { warning?: any }) {
    if (!isObject(account) || !Array.isArray(account.warning)) {
      return false;
    }
    return account.warning.reduce((ret: boolean, warning: any) => {
      return warning.length > 0 || ret;
    }, false);
  }

  accountsContainWarning(accounts: any) {
    if (!Array.isArray(accounts)) {
      return false;
    }
    return accounts.reduce((ret, account) => {
      return this.accountContainsWarning(account) || ret;
    }, false);
  }

  accountContainsMailTypeCode(account: any) {
    return isString(account.accountMailTypeCode);
  }

  accountContainsMailingAddress(account: any) {
    return isString(account.mailingAddressId) || isNumber(account.mailingAddressId);
  }

  accountContainsPrimaryApplicant(account: any) {
    return !isUndefined(account.primaryApplicantId);
  }

  accountListContainsPrimaryApplicant(accountList: any[]) {
    return accountList.every((account) => {
      return this.accountContainsPrimaryApplicant(account);
    });
  }

  getAccountsForApplicant(accounts: any[], applicantId: number) {
    return accounts.filter((account) => {
      return account.applicants.some((accountApplicant: { applicantId: number }) => {
        return accountApplicant.applicantId === applicantId;
      });
    });
  }
}
export default EnrollmentAccountFactory;

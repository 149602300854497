const DEPENDENCYTYPES = {
  IWorkspaceStore: Symbol.for("IWorkspaceStore"),
  IPlaidStore: Symbol.for("IPlaidStore"),
  IWorkspaceMetadataStore: Symbol.for("IWorkspaceMetadataStore"),
  IDisclosureStore: Symbol.for("IDisclosureStore"),
  IFundingStore: Symbol.for("IFundingStore"),
  IBusinessInfoStore: Symbol.for("IBusinessInfoStore"),
  ICipStore: Symbol.for("ICipStore"),
  IHttpErrorHandler: Symbol.for("IHttpErrorHandler"),
  IRouterService: Symbol.for("IRouterService"),
  IApplicantValidationFactory: Symbol.for("IApplicantValidationFactory"),
  IEnrollmentValidationFactory: Symbol.for("IEnrollmentValidationFactory"),
  IEnrollmentApplicantCipFactory: Symbol.for("IEnrollmentApplicantCipFactory"),
  IAuthenticationService: Symbol.for("IAuthenticationService"),
  IUiFieldsFactory: Symbol.for("IUiFieldsFactory"),
  IWorkspaceTabEntityFactory: Symbol.for("IWorkspaceTabEntityFactory"),
  ApplicantTabs: Symbol.for("ApplicantTabs"),
  EnrollmentTabs: Symbol.for("EnrollmentTabs"),
  PERSON: Symbol.for("PERSON"),
  BUSINESS: Symbol.for("BUSINESS"),
  IWorkflowStore: Symbol.for("IWorkflowStore"),
  ICardContentHeightUtil: Symbol.for("ICardContentHeightUtil"),
  INotificationFactory: Symbol.for("INotificationFactory"),
  IColourSchemeUtils: Symbol.for("IColourSchemeUtils"),
  IPerson: Symbol.for("IPerson"),
  IAddress: Symbol.for("IAddress"),
  IEmail: Symbol.for("IEmail"),
  HttpHandlerConfig: Symbol.for("HttpHandlerConfig")
};
export default DEPENDENCYTYPES;

import {DaysOfTheWeek, MonthList, MonthObject} from '@/common/constant/DateUtilConstant'
import {injectable} from 'inversify'
import { isNumber } from 'lodash';

@injectable()
class DateUtil {
  getMonthObject() {
    return MonthObject
  }

  getMonthNumberList() {
    return MonthList
  }

  daysOfTheWeek() {
    return DaysOfTheWeek
  }

  daysArray() {
    const returnArray = []

    for (let i = 1; i < 32; i++) {
      returnArray.push(i)
    }

    return returnArray
  }

  hoursArray() {
    const returnArray = []
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        returnArray.push('0' + i)
      } else {
        returnArray.push(i)
      }
    }
    return returnArray
  }

  minutesArray() {
    const returnArray = []
    for (let i = 0; i < 60; i++) {
      if (i < 10) {
        returnArray.push('0' + i)
      } else {
        returnArray.push(i)
      }
    }
    return returnArray
  }

  padYYYMM = function (year: string | number, month: string | number) {
    if (isNumber(year)) {
      year = year.toString()
    }

    if (isNumber(month)) {
      month = month.toString()
    }

    return `${year}-${month}-01`
  }

  getYearListFromToday = function (amount: number) {
    const currentYear = new Date().getFullYear()
    const dateList = [...Array(amount)]
    return dateList.map(function (_date, index) {
      return index + currentYear
    })
  }

  daysToMilliseconds = function (days: number) {
    return days * 86400000
  }

  millisecondsToDays = function (milliseconds: number) {
    return milliseconds / 86400000
  }

  addDays = function (date: Date, days: number) {
    date.setDate(date.getDate() + days)
  }

  getDateAtEndOfDay = (date: Date) => {
    const endDate = new Date(date.toISOString().split('T')[0])
    endDate.setTime(endDate.getTime() + this.daysToMilliseconds(1) - 1)
    return endDate
  }

  // Convert to YYYY-MM-DD format
  dateToInputDate = (date: Date) =>
    `${date.getFullYear()}-${(1 + date.getMonth()).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`

  getDateAtBeginningOfDay = function (date: Date) {
    return new Date(date.toISOString().split('T')[0])
  }
}

export default DateUtil

import {inject, injectable} from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import type BusinessResolution from "@/common/data/BusinessResolution";
import type RestResponse from "@/common/data/RestResponse";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import type BusinessResolutionFields from "@/common/data/BusinessResolutionFields";
@injectable()
class BusinessResolutionService {

    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

    getApplicantResolution(
        workspaceUUID: string,
        applicantId: number
    ): Promise<BusinessResolution | null> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/resolution`,
            {},
            ResponseTypes.Payload
        );
    }

    getFields(
        workspaceUUID: string,
        businessApplicantId: number
    ): Promise<BusinessResolutionFields> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/business-resolution-fields`,
            {},
            ResponseTypes.Payload
        );
    }

    save(
        workspaceUUID: string,
        applicantId: number,
        resolution: BusinessResolution
    ): Promise<BusinessResolution> {
        return resolution.applicantResolutionId
            ? this.updateApplicantResolution(workspaceUUID, applicantId, resolution.applicantResolutionId, resolution)
            : this.createApplicantResolution(workspaceUUID, applicantId, resolution)
    }

    updateApplicantResolution(
        workspaceUUID: string,
        applicantId: number,
        applicantResolutionId: number,
        resolution: BusinessResolution,
    ): Promise<BusinessResolution> {
        return this.httpHandler.put(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/resolution/${applicantResolutionId}`,
            resolution,
            {},
            ResponseTypes.Payload
        )
    }
    createApplicantResolution(
        workspaceUUID: string,
        applicantId: number,
        resolution: BusinessResolution,
    ): Promise<BusinessResolution> {
        return this
            .httpHandler
            .post(
                `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/resolution`,
                resolution,
                {},
                ResponseTypes.Payload
            )
    }

    delete(
        workspaceUUID: string,
        businessApplicantId: number,
        resolution: BusinessResolution
    ): Promise<RestResponse<void>> {
        return this.httpHandler.delete(`{{boltsbranch}}/workspace/${workspaceUUID}/applicant/${businessApplicantId}/resolution/${resolution.applicantResolutionId}`)
    }

}

export default BusinessResolutionService;

import {cloneDeep, isEmpty, isString, isUndefined, merge} from "lodash";
import {BltIframeConstants} from "@/common/constant/BltIframeConstant";
import LogService from "@/common/services/Log/LogService";
import {$inj, $injByInterface} from "@/common/decorators/depinject";
import DIRECTIVE_MSG from "@/common/messages/directive.messages";
import {Base64Factory, BltIframeFactory} from "@/common/services/services.module";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import {DisclosureConstant} from "@/common/constant/DisclosureConstant";
import {BroadcastConstant} from "@/common/constant/BroadcastConstant";
import {DialogConstant} from "@/common/constant/DialogConstant";
import bltSignButton from "@/common/components/bltSignButton/bltSignButton.vue";
import type {DirectiveBinding, VNode} from "vue";
import {createApp, type Directive, h} from "vue";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import type {IDisclosureStore} from "@/common/services/Disclosure/IDisclosureStore";

const defaultHtml =
  "" + "<html lang=\"en\">" + "   <body>" + '       <div style="min-height:300px; width:100%;"></div>' + "   </body>" + "</html>";
let timeIntervel: any;

const getIframeDoc = (el: { contentDocument: any; contentWindow: any; }): Document | null => {
  if (el.contentWindow?.document?.getElementById) {
    return el.contentWindow?.document
  } else if (el.contentDocument?.getElementById) {
    return el.contentDocument
  } else {
    return null
  }
};

const getIframeBody = (el: HTMLIFrameElement) => {
  const doc = getIframeDoc(el);
  return !isUndefined(doc) ? doc?.body : undefined;
};

const setIframeHtml = (el: HTMLIFrameElement, html: string) => {
  const iframeBody = getIframeBody(el);
  if (iframeBody) {
    iframeBody.innerHTML = html
  }
};

const getDictionary = (el: HTMLIFrameElement, dictionary: any) => {
  let newDictionary = merge(dictionary);
  const metaData: any = {};

  newDictionary = Object.keys(newDictionary).map((tagKey) => {
    return getValueFromHtml(el, newDictionary[tagKey]);
  });

  metaData.oneSigned = newDictionary.some((tag: { tagType: string; signed: boolean }) => {
    return tag.tagType === BltIframeConstants.SIGNATURE && tag.signed === true;
  });

  metaData.oneChecked = newDictionary.some((tag: { tagType: string; value: boolean }) => {
    return tag.tagType === BltIframeConstants.CHECKBOX && tag.value === true;
  });

  const retDictionary = newDictionary.reduce((col: { [x: string]: any }, tag: { tagName: string | number }) => {
    col[tag.tagName] = tag;
    return col;
  }, {});
  return {
    metaData: metaData,
    dictionary: retDictionary
  };
};

const getValueFromHtml = (el: HTMLIFrameElement, tag: { tagName: string; tagType: string; value: any; signed: string }) => {
  const logService = $inj(LogService);
  const disclosureStore = $injByInterface<IDisclosureStore>("IDisclosureStore");
  const iframeDoc = getIframeDoc(el);
  const tagElem = iframeDoc?.getElementById(tag.tagName) as HTMLElement

  if (tagElem === null) {
    logService.error(DIRECTIVE_MSG.BLT_IFRAME.TAG_FORMMAT.replace("${tagName}", tag.tagName));
    return tag;
  }

  switch (tag.tagType) {
    case BltIframeConstants.TEXTBOX:
      tag.value = (<HTMLTextAreaElement> tagElem)?.value;
      break;
    case BltIframeConstants.CHECKBOX:
      tag.value = (<HTMLInputElement> tagElem)?.checked;
      break;
    case BltIframeConstants.SELECT:
      tag.value = -1 !== (<HTMLSelectElement> tagElem).selectedIndex
          ? ((<HTMLOptionElement> (<HTMLSelectElement> tagElem)[(<HTMLSelectElement> tagElem).selectedIndex])).value
          : "";
      break;
    case BltIframeConstants.SIGNATURE:
      tag.value = JSON.parse(tag.value);
      tag.signed = disclosureStore.disclosureDictionary.data[tag.tagName].value.signed;
      tag.value.signed = tag.signed;
      tag.value = JSON.stringify(tag.value);
      break;
  }
  return tag;
};

const writeHtml = (iframe: HTMLIFrameElement, html: string, vnode: VNode<HTMLIFrameElement>, dictionary?: any, maxCardContentHeight?: number) => {
  const bltIframeFactory = $inj(BltIframeFactory);
  html = isString(html) ? html : defaultHtml;
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  if(iframeDoc == null) { return }
  iframeDoc.open();
  iframeDoc.write(html);
  iframeDoc.close();

  waitForIframeLoad(iframe).then(() => {
    const iframeDoc = getIframeDoc(iframe);
    if (!iframeDoc) { return; }

    if (!isEmpty(dictionary)) {
      const tagMap = bltIframeFactory.getElementTagMap(iframe);
      injectSignatures(dictionary as any, iframeDoc);
      injectSelect(iframeDoc, dictionary as any);
      bltIframeFactory.setTextboxValues(tagMap, dictionary as any);
    }

    if (maxCardContentHeight) {
      /**BLOCK OF CODE TO CALCULATE IFRAME CONTENT BODY HEIGHT and RESIZE IFRAME HEIGHT */
      const marginTop = parseInt(window.getComputedStyle(iframeDoc.body).getPropertyValue('margin-top'));
      const marginBottom = parseInt(window.getComputedStyle(iframeDoc.body).getPropertyValue('margin-bottom'));
      const paddingTop = parseInt(window.getComputedStyle(iframeDoc.body).getPropertyValue('padding-top'));
      const paddingBottom = parseInt(window.getComputedStyle(iframeDoc.body).getPropertyValue('padding-bottom'));
      const iframeBodyHeight = iframeDoc.body.scrollHeight + marginTop + marginBottom + paddingTop + paddingBottom;

      //this block of code is being used only from ShowDisclosre type of screen
      if (iframeBodyHeight > maxCardContentHeight) {
        iframe.height = `${maxCardContentHeight}`;
      }
      else {
        iframe.height = `${iframeBodyHeight + 35}`;
      }
      const parentWrapperDiv = (iframe as HTMLIFrameElement).parentElement;
      if (parentWrapperDiv)
        parentWrapperDiv.style.height = `${iframe.height}px`;
    }
  });
};

const updateIframe = (dictionary: any, html: string, el: HTMLIFrameElement, uuid: any, enrollmentId: any, vnode: VNode<HTMLIFrameElement>, maxCardContentHeight?: number) => {
  if (uuid && !isUndefined(uuid) && !isEmpty(dictionary) && isString(html)) {

    processHtml(dictionary, html, el, uuid, enrollmentId).then((data: { html: string; dictionary: any }) => {
      writeHtml(el, data.html, vnode, data.dictionary, maxCardContentHeight);
    });
  } else {
    writeHtml(el, html, vnode, null, maxCardContentHeight);
  }
};

const waitForIframeLoad = (ele: HTMLIFrameElement) => {
  return new Promise<HTMLElement>((resolve) => {
    const timeInterval = setInterval(() => {
      const iframeBody = getIframeBody(ele);
      const iframeDoc = getIframeDoc(ele);
      
      if (iframeBody && iframeDoc) {
        clearInterval(timeInterval);
        resolve(iframeBody);
      }
    }, 20);
  });
};

const processHtml = (dictionary: any, html: string, content: HTMLIFrameElement, uuid: string, enrollmentId: number) => {
  const bltIframeFactory = $inj(BltIframeFactory);

  const dictionaryCloned = cloneDeep(dictionary),
    sortedDictionary = bltIframeFactory.sortDictionaryTags(dictionaryCloned);

  bltIframeFactory.getCheckboxReplacements(sortedDictionary);

  bltIframeFactory.getTextboxReplacements(sortedDictionary);

  bltIframeFactory.getSelectReplacements(sortedDictionary);

  bltIframeFactory.getSignatureReplacements(sortedDictionary);

  return bltIframeFactory.injectApplicantImages(sortedDictionary, uuid, enrollmentId).then(() => {
    const finalHTML = bltIframeFactory.replaceHtmlTags(html, sortedDictionary);
    return { html: finalHTML, dictionary: sortedDictionary };
  });
};

const waitForSignature = (signature: { originalTagName: string }, content: any): any => {
  let timeIntervel = 0;
  const promise = new Promise<any>((resolve, reject) => {
    const elemInterval = setInterval(() => {
      const signatureDiv = content.querySelectorAll("[" + CSS.escape(signature.originalTagName) + "]")[0];

      if (!isUndefined(signatureDiv)) {
        clearInterval(elemInterval);
        resolve(signatureDiv);
      }
      if (timeIntervel / 5 === 30000) {
        clearInterval(elemInterval);
        reject();
      }
      timeIntervel++;
    }, 5);
    const broadcastService = $inj(BroadcastService);
    broadcastService.on("$destroy", () => {
      reject();
      clearInterval(elemInterval);
    });
  });
  return promise;
};

const injectSignatures = (dictionary: { signature: any[] }, content: Document) => {
  const base64Factory = $inj(Base64Factory);
  dictionary.signature.forEach((signature) => {
    const disclosureStore = $injByInterface<IDisclosureStore>("IDisclosureStore");
    disclosureStore.disclosureDictionary.data[signature.tagName] = signature;
    if (!isUndefined(signature.base64) && isString(signature.base64.imageDataBase64)) {
      const signatureData = base64Factory.prefixBase64(signature.base64.imageDataBase64, signature.base64.imageFormat);
      waitForSignature(signature, content).then(
        (signatureDiv: { setAttribute: (arg0: string, arg1: any) => void; appendChild: (arg0: any) => void }) => {
          signatureDiv.setAttribute("id", signature.tagName);
          const ComponentApp = createApp({
            setup() {
              return () =>
                h(bltSignButton, {
                  bltEditable: signature.editable,
                  signed: signature.value.signed,
                  data: signatureData,
                  tagName: signature.tagName
                });
            }
          });
          const wrapper = document.createElement("div");
          ComponentApp.mount(wrapper);
          const getSignatureId = content.getElementById(signature.tagName);
          if (getSignatureId) {
            getSignatureId.appendChild(wrapper);
          }
        }
      );
    }
  });
};

const injectSelect = (iframeDoc: Document, dictionary: { select: any[] }) => {
  dictionary.select.forEach((tag) => {
    if (isString(tag.tagName) && isString(tag.value)) {
      const tagElem = iframeDoc.getElementById(tag.tagName) as HTMLSelectElement

      if (tagElem) {
        tagElem.value = tag.value;
      } else {
        console.warn(`Tag ${tag.tagName} not found in iFrame`)
      }
    }
    if (isString(tag.tagName)) {
      const tagElem = iframeDoc.getElementById(tag.tagName) as HTMLSelectElement
      if (tagElem) {
        tagElem.disabled = !tag.editable
      } else {
        console.warn(`Tag ${tag.tagName} not found in iFrame`)
      }
    }
  });
};

interface bltIframeValues {
  html: string,
  dictionary: any,
  disclosureStatus: string,
  modalStatus: string,
  maxCardContentHeight?: number
}

const bltIframe: Directive<HTMLIFrameElement, bltIframeValues> = {
  // name: "bltIframe",

  mounted: (
      el: HTMLIFrameElement,
      binding: DirectiveBinding<bltIframeValues>,
      vnode: VNode<HTMLIFrameElement>
  ) => {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    const uuid = workspaceStore.workspaceUUID;
    const enrollmentId = workspaceStore.enrollment.enrollmentId;
    const { html, dictionary, maxCardContentHeight } = binding.value;
    updateIframe(dictionary, html, el, uuid, enrollmentId, vnode, maxCardContentHeight);
  },

  updated(
      el: HTMLIFrameElement,
      binding: DirectiveBinding<bltIframeValues>,
      vnode: VNode<HTMLIFrameElement>
  ) {
    const disclosureStore = $injByInterface<IDisclosureStore>("IDisclosureStore");
    const { html, dictionary, disclosureStatus, modalStatus, maxCardContentHeight } = binding.value;
    const hasDictionaryProperties = dictionary && Object.getOwnPropertyNames(dictionary).length;

    if (hasDictionaryProperties
      && disclosureStatus !== DisclosureConstant.COMPLETE
      && modalStatus !== DialogConstant.CLOSE) {
      const getDictionaryObj = getDictionary(el, dictionary);
      disclosureStore.disclosureDictionary.data = getDictionaryObj.dictionary;
    } else {
      updateIframe(dictionary, html, el, null, null, vnode, maxCardContentHeight);
    }
  },
  beforeUnmount(
      el: HTMLIFrameElement,
      binding: DirectiveBinding<bltIframeValues>,
      vnode: VNode<HTMLIFrameElement>,
  ) {
    const { disclosureStatus, modalStatus, dictionary } = binding.value;
    const hasDictionaryProperties = dictionary && Object.getOwnPropertyNames(dictionary).length;

    if (hasDictionaryProperties
      && disclosureStatus !== DisclosureConstant.COMPLETE
      && modalStatus !== DialogConstant.CLOSE) {
      const disclosureStore = $injByInterface<IDisclosureStore>("IDisclosureStore");
      const getDictionaryObj = getDictionary(el, dictionary);

      disclosureStore.disclosureDictionary.data = getDictionaryObj.dictionary;
    }

    if (disclosureStatus !== DisclosureConstant.COMPLETE && modalStatus !== DialogConstant.CLOSE) {
      const broadcastService = $inj(BroadcastService);
      broadcastService.broadcast(BroadcastConstant.IFRAME_ACCEPT, true);
    }
  },
  unmounted(
      el: HTMLIFrameElement,
      binding: DirectiveBinding<bltIframeValues>,
      vnode: VNode<HTMLIFrameElement>
  ) {
    const broadcastService = $inj(BroadcastService);
    broadcastService.broadcast(BroadcastConstant.IFRAME_ACCEPT, false);
    if (!isUndefined(timeIntervel)) {
      Promise.reject();
      clearInterval(timeIntervel);
    }
  }
};

export default bltIframe;

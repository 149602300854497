import {inject, injectable} from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type ApplicantPowers from "@/common/data/ApplicantPowers";

@injectable()
class BusinessResolutionPowersService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {
  }

  getAllPowersList(
  ): Promise<Array<ApplicantPowers>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/applicant-powers`,
      {},
      ResponseTypes.Payload
    )
  }
}

export default BusinessResolutionPowersService;
import ApplicantSuggestionFactory from "@/common/services/Applicant/applicantSuggestionFactory";
import ConversionFactory from "@/common/services/utils/conversionFactory";
import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import {cloneDeep, merge, isEqual} from "lodash";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { $inj } from "@/common/decorators/depinject";
import { AddressConstant } from "@/common/constant/AddressConstant";
import { IdentificationConstant } from "@/common/constant/IdentificationConstant";
import { DisplayConstant } from "@/common/constant/DisplayConstant";
import type ApplicantIdentification from "@/common/data/ApplicantIdentification";

@injectable()
class ApplicantIdentificationFactory {
  constructor(
    @inject(ApplicantSuggestionFactory) private applicantSuggestionFactory: ApplicantSuggestionFactory,
    @inject(ConversionFactory) private conversionFactory: ConversionFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

 getAll(workspaceUUID: string, applicantId: number, typeCodes: any) {
    return Promise.all([
      this.applicantSuggestionFactory.get(workspaceUUID, applicantId, IdentificationConstant.IDENTIFICATION),
      this.httpHandler.get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/identification`,
        {},
        ResponseTypes.Payload
      )
    ]).then((response) => {
      const suggestedIdentificationTypes = response[0];
      let identificationList = Array.isArray(response[1]) ? response[1] : [];
      identificationList = this.applicantSuggestionFactory.applyRequiredTypes(
        identificationList,
        IdentificationConstant.IDENTIFICATIONTYPE,
        suggestedIdentificationTypes
      );

      identificationList = isEqual(identificationList, [{}])
        ? [{ identificationType: typeCodes[0].typeCode, countryCode: AddressConstant.DEFAULT_COUNTRY_CODE }]
        : identificationList;
      return identificationList.map((identification) => {
        return this.create(workspaceUUID, applicantId, identification, suggestedIdentificationTypes);
      });
    });
  }

  create(workspaceUUID: string, applicantId: number, identification: any, requiredTypes: any) {
    return new Identification(workspaceUUID, applicantId, identification, requiredTypes);
  }
}

export class Identification {
  saving = false;
  requiredTypes: string[];
  workspaceUUID: string;
  applicantId: number;
  DTO: ApplicantIdentification;
  issueDate: string | null;
  expiryDate: string | null;
  deleting = false;
  required = false;
  private httpHandler? = $inj(HttpHandler);

  constructor(workspaceUUID: string, applicantId: number, response: any, requiredTypes: string[]) {
    this.requiredTypes = requiredTypes || [];
    this.workspaceUUID = workspaceUUID;
    this.applicantId = applicantId;

    this.DTO = response;
    this.DTO.issueCountry = this.DTO.issueCountry || AddressConstant.DEFAULT_COUNTRY_CODE;

    this.issueDate = this.DTO.issueDate ? this.DTO.issueDate : null;

    this.expiryDate = this.DTO.expiryDate ? this.DTO.expiryDate : null;

    Object.defineProperty(this, DisplayConstant.REQUIRED, {
      get: () => {
        return this.requiredTypes.some((requiredType: any) => {
          return requiredType === this.DTO.identificationType;
        });
      }
    });

    //used for $filter('remainingTypeCodes')
    Object.defineProperty(this, IdentificationConstant.IDENTIFICATIONTYPE, {
      get: () => {
        return this.DTO.identificationType;
      }
    });
  }

  onChange(value: any, type: "identificationType" | "issueCountry" | "issueState" | "issueDate" | "expiryDate") {
    this.DTO[type] = value;
  }

  async save() {
    /*change to let if data changed*/
    const message = cloneDeep(this.DTO);
    const promise = this.DTO.identificationId
      ? this.httpHandler?.put(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${this.workspaceUUID}/applicant/${this.applicantId}/identification/${this.DTO.identificationId}`,
          message,
          {},
          ResponseTypes.Payload
        )
      : this.httpHandler?.post(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${this.workspaceUUID}/applicant/${this.applicantId}/identification`,
          message,
          {},
          ResponseTypes.Payload
        );
    this.saving = true;
    return promise
      ?.then((response: any) => {
        const mergedResponse = merge(response[0], this.DTO);
        return mergedResponse;
      })
      .finally(() => {
        this.saving = false;
      });
  }

  async delete(identificationList: any) {
    this.deleting = true;

    /*change to let if data changed*/
    const promise = this.DTO.identificationId
      ? this.httpHandler?.delete(
          `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${this.workspaceUUID}/applicant/${this.applicantId}/identification/${this.DTO.identificationId}`,
          {},
          ResponseTypes.Data
        )
      : Promise.resolve(this.DTO);

    promise
      ?.then(() => {
        identificationList.splice(identificationList.indexOf(this), 1);
      })
      .finally(() => {
        this.deleting = false;
      });
  }
}

export default ApplicantIdentificationFactory;

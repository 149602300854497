import { injectable, inject } from "inversify";
import TaxIdFilter from "@/common/services/filters/taxIdFilter";
import {isUndefined} from "lodash";

@injectable()
class SearchTaxIdFilter {
  constructor(@inject(TaxIdFilter) private taxIdFilter: TaxIdFilter) { }

  searchTaxId(searchItem?: { taxIdLast4?: string; taxId?: string; workspaceType?: string }) {
    if (searchItem?.taxIdLast4 || searchItem?.taxId) {
      const searchInput = searchItem.taxIdLast4 || searchItem.taxId;
      if (!isUndefined(searchInput)) return this.taxIdFilter.taxId(searchInput);
      return "";
    } else if (searchItem?.workspaceType) {
      return "Workspace Type: " + searchItem.workspaceType;
    } else {
      return "";
    }
  }
}

export default SearchTaxIdFilter;
<template>
  <form
    ref="bannoEnrollmentForm"
    v-show="false"
    v-if="!isFormSubmitted"
    target="_blank"
    rel="noopener noreferrer"
    :action="endpointURL"
    method="POST"
    @submit.prevent="submitForm"
  >
    <input type="text" id="accountNumberInput" name="account-number" :value="accountNumber" />
    <input type="text" id="phoneNumberInput" name="phone-number" :value="phoneNumber" />
    <input type="text" id="emailInput" name="email" :value="email" />
    <input type="submit">
  </form>
</template>

<script setup lang="ts">
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import { $inj } from "@/common/decorators/depinject";
import { ConstantFactory } from "@/common/services/services.module";
import { onMounted, ref } from "vue";

const constantFactory = $inj(ConstantFactory);
const bannoEnrollmentForm = ref(); //for $refs=bannoEnrollmentForm
const isFormSubmitted = ref(false);

const props = defineProps(["accountNumber", "phoneNumber", "email"]);

const endpointURL = ref(constantFactory.getProp(SERVICE_PATH_CONSTANTS.BANNO_ENROLLMENT_POST_URL));

onMounted(() => {
  const submit = function () {
    bannoEnrollmentForm.value.submit();
  };
});

const submitForm = () => {
  bannoEnrollmentForm.value.submit();
};

defineExpose({
  props,
  submitForm
});
</script>

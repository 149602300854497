export class WorkspaceConstants {
  static readonly TAX_ID: string = "TAX_ID";
  static readonly EIN: string = "EIN";
  static readonly PERSON: string = "PERSON";
  static readonly BUSINESS: string = "BUSINESS";
  static readonly FIRST_NAME: string = "firstname";
  static readonly LAST_NAME: string = "lastname";
  static readonly FULL_NAME: string = "fullname";
  static readonly TAXID: string = "taxid";
  static readonly PHONE: string = "phonenumber";
  static readonly PHONE_EXT: string = "phonenumberextension";
  static readonly EMAIL: string = "email";
  static readonly BUSINESS_NAME: string = "businessname";
  static readonly EXISTING_WORKSPACE: string = "Existing workspaces";
  static readonly RECENT_WORKSPACES: string = "Recent workspaces";
  static readonly SEARCH_LABEL: string = "Search workspaces";
  static readonly SEARCH_THINGS: string = "Search things like name, business, tax ID, phone number, or email";
  static readonly SEARCH_RESULTS: string = "Search results";
  static readonly WORKSPACE_INFO: string = "Workspace Info";
  static readonly APPLICANTS: string = "Applicants";
  static readonly UUID: string = "UUID";
  static readonly ENROLLMENTS: string = "Enrollments";
  static readonly OPEN_WORKSPACE: string = "Open this workspace";
  static readonly OPEN: string = "Open";
  static readonly CLOSED: string = "Closed";
  static readonly WORKSPACETYPE: string = "WORKSPACE";
  static readonly APPLICANTTYPE: string = "APPLICANT";
  static readonly BUSINESSTYPE: string = "business";
  static readonly ENROLLMENTTYPE: string = "enrollment";
  static readonly SEARCH_EXISTING_PEOPLE_LABEL: string = "Search existing people";
  static readonly SEARCH_EXISTING_BUSINESS_LABEL: string = "Search existing businesses";
  static readonly SEARCH: string = "Search";
  static readonly ADD_PERSON_WORKSPACE_TITLE: string = "Add a person to this workspace";
  static readonly ADD_BUSINESS_WORKSPACE_TITLE: string = "Add a business to this workspace";
  static readonly CREATE_NEW_PERSON: string = "Create new person";
  static readonly CREATE_NEW_BUSINESS: string = "Create new business";
  static readonly EXISTING_CUSTOMERS: string = "Existing customers";
  static readonly EMPTY_SEARCH_MESSAGE_PERSON: string =
    "Start by searching to see if the person <br/> is already in the system";
  static readonly EMPTY_SEARCH_MESSAGE_BUSINESS: string =
    "Start by searching to see if the business <br/> is already in the system";
  static readonly EMP_WORKSPACE: string = " workspace";
  static readonly EMP_LANDING_NEW: string = "New ";
  static readonly CONFIRM_UNLOCK: string = "Are you sure you want to unlock this workspace?";
  static readonly CONFIRM_LOCK: string = "Are you sure you want to lock this workspace?";
  static readonly REMOVE_APPLICANT: string = "Remove applicant";
  static readonly CONFIRM_REMOVE_APPLICANT: string =
    "Removing an applicant from the workspace cannot be undone and will remove all of the applicant's information entered in the enrollment workflow. Are you sure you want to do this?";
  static readonly PERSON_INFO_LABEL: string = "Person info";
  static readonly DOB_LABEL: string = "DOB";
  static readonly ADDRESS_LABEL: string = "Address";
  static readonly PHONE_LABEL: string = "Phone";
  static readonly EMAIL_LABEL: string = "Email";
  static readonly APPLICANT_CREATED: string = "Applicant created";
  static readonly ADD_WORKSPACE: string = "Add to this workspace";
  static readonly DATE_ESTABLISHMENT_LABEL: string = "Date of Establishment";
  static readonly APPLICANT: string = "applicant";
  static readonly CUSTOMER: string = "customer";
  static readonly NOMATCH: string = "No matches for ";
  static readonly EXISTING_WORKSPACE_INFO: string = "Existing workspace info";
  static readonly OPEN_EXISTING_WORKSPACE: string = "Open existing workspace";
  static readonly BUSINESS_INFO: string = "Business info";
  static readonly CATEGORY_LABEL: string = "Category";
  static readonly ACCOUNT_CATEGORY: string = "Account Category";
  static readonly NO_DATA: string = "No data";
  static readonly ELLIPSES_EMAIL_TITLE: string = "Email a continuation link";
  static readonly ELLIPSES_EMAIL_KEY: string = "Email";
  static readonly ELLIPSES_STATUS_KEY: string = "Status";
  static readonly ELLIPSES_ERROR_LOG_TITLE: string = "Error log";
  static readonly ELLIPSES_ERROR_LOG_KEY: string = "Error";
  static readonly ELLIPSES_STATE_HISTORY_TITLE: string = "Open state history module";
  static readonly ELLIPSES_STATE_HISTORY_KEY: string = "History";
  static readonly ELLIPSES_INVALID_CHOICE: string = "Invalid ellipses menu choice selected";
  static readonly CONFIRM_DIALOG: string = "Yes, proceed";
  static readonly CANCEL_DIALOG: string = "Cancel";
  static readonly NO_ERRORS_MESSAGE: string = "No errors found";
  static readonly CLOSE_WORKSPACE: string = "Close workspace";
  static readonly CLOSE_WORKSPACE_BODY: string =
    "Your current workspace will be saved. If you want to return to it, you will need to search existing workspaces.";
  static readonly PHONETEXT: string = "phone";
  static readonly CREATED_BY: string = "Created by";
  static readonly CREATED_BY_ME: string = "Me";
  static readonly CREATED_BY_ANY: string = "Any";
  static readonly FILTER_BY: string = "Filter by";
  static readonly FILTER_BY_BRANCH: string = "BRANCH";
  static readonly FILTER_BY_ENDUSER: string = "ENDUSER";
  static readonly FILTER_BY_STATUS: string = "status";
  static readonly FILTER_BY_TYPE: string = "type";
  static readonly CLEAR_FILTERS: string = "Clear filters";

}

export default WorkspaceConstants;

const dateFormat = 'YYYY-MM-DD'
const START_DATE = 'startDate'
const END_DATE = 'endDate'
const TIMEFRAME = 'timeframe'

const MonthList = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const MonthObject = {
  1: { full: "January", short: "Jan", days: 31 },
  2: { full: "February", short: "Feb", days: 29 }, // Allow people to set Feb. 29th
  3: { full: "March", short: "Mar", days: 31 },
  4: { full: "April", short: "Apr", days: 30 },
  5: { full: "May", short: "May", days: 31 },
  6: { full: "June", short: "Jun", days: 30 },
  7: { full: "July", short: "Jul", days: 31 },
  8: { full: "August", short: "Aug", days: 31 },
  9: { full: "September", short: "Sept", days: 30 },
  10: { full: "October", short: "Oct", days: 31 },
  11: { full: "November", short: "Nov", days: 30 },
  12: { full: "December", short: "Dec", days: 31 }
};

const DaysOfTheWeek = {
  mon: {
    full: "Monday"
  },
  tue: {
    full: "Tuesday"
  },
  wed: {
    full: "Wednesday"
  },
  thu: {
    full: "Thursday"
  },
  fri: {
    full: "Friday"
  },
  sat: {
    full: "Saturday"
  },
  sun: {
    full: "Sunday"
  }
};

export { MonthList, MonthObject, DaysOfTheWeek, dateFormat, START_DATE, END_DATE, TIMEFRAME };

import {inject, injectable} from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import type BusinessApplicantSigner from "@/common/data/BusinessApplicantSigner";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type RestResponse from "@/common/data/RestResponse";

@injectable()
class BusinessApplicantSignerService {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

    /**
     * @NOTE: This endpont will return NO payload if a signer doesn't exist. Be sure to handle null!
     */
    get(
        workspaceUUID: string,
        businessApplicantId: number,
        applicantId: number
    ): Promise<BusinessApplicantSigner | null> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}/signer`,
            {},
            ResponseTypes.Payload
        )
    }

    save(
        workspaceUUID: string,
        businessApplicantId: number,
        applicantId: number,
        DTO: BusinessApplicantSigner
    ): Promise<BusinessApplicantSigner> {
        return (DTO.signerId)
            ? this.httpHandler.put(
                `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}/signer/${DTO.signerId}`,
                DTO,
                {},
                ResponseTypes.Payload
            )
            : this.httpHandler.post(
                `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}/signer`,
                DTO,
                {},
                ResponseTypes.Payload
            )
    }

    delete(
        workspaceUUID: string,
        businessApplicantId: number,
        applicantId: number,
        signerId: number
    ): Promise<RestResponse<void>> {
        return this.httpHandler.delete(`{{boltsbranch}}/workspace/${workspaceUUID}/applicant/${businessApplicantId}/applicant/${applicantId}/signer/${signerId}`)
    }
}

export default BusinessApplicantSignerService;
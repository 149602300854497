import { Container, injectable } from "inversify";
import { autobindIoC } from "./decorators/ioc.autobind";
import * as services from './services/services.module';

const container: Container = new Container();

// todo(mikol): Replace with @Service annotations
autobindIoC(container, services);

//todo(mikol): We should eventually make the container a private singleton
export default container;

/**
 * Automatically binds the decorated class to a singleton IOC {@link container}.
 * the name `Service` was chosen to mimic our current API Kotlin/Spring stack IOC decorators.
 *
 *  @Deprecated - We discovered that inversify defaults to a transient scope, which means that all usages of this
 *  annotation will result in a new instance of the class being created every time it is requested from the container.
 *  this was not the intent of this annotation at all.
 *
 *  Please use the `SingletonService` annotation instead when you want a single instance of a dependency to live within the
 *  IOC container.
 *
 *  Please use the `Transient` annotation when you want a new instance of a dependency to be created every time
 *  it is requested
 *
 *  Once all instances of this class have been removed, we will refactor and rename the `SingletonService`
 *  annotation to `Service` and remove this method.
 *
 *  Thanks! 🤠 - Cody Mikol
 *
 *  @Service
 *  class MyService { ... }
 *
 * @param target -- the target class constructor
 */
export function Service<T extends abstract new (...args: never) => unknown>(target: T) {
  injectable()(target.prototype)
  container.bind(target).toSelf()
}

/**
 * Automatically binds the decorated class to a singleton IOC {@link container}.
 * the name `Service` was chosen to mimic our current API Kotlin/Spring stack IOC decorators.
 *
 *  @Service
 *  class MyService { ... }
 *
 * @param target -- the target class constructor
 */
export function SingletonService<T extends abstract new (...args: never) => unknown>(target: T) {
  injectable()(target.prototype)
  container.bind(target).toSelf().inSingletonScope()
}

/**
 * Automatically binds the decorated class to a transient IOC {@link container}.
 * this means any time this particular class is requested from the container, a new instance will be created.
 *
 * @Transient
 * class MyService { ... }
 *
 * @param target -- the target class constructor
 */
export function Transient<T extends abstract new (...args: never) => unknown>(target: T) {
  injectable()(target.prototype)
  container.bind(target).toSelf().inTransientScope()
}

import {injectable} from 'inversify'
import {SingletonService} from '@/common/dependency.configs'
import InactivityModalUtil from '@/open/services/utils/InactivityModalUtil'
import {$inj} from '@/common/decorators/depinject'

export const timeoutWarningThresholdSeconds = 60
export let tokenTimeoutMilliseconds = 0

const oneSecond = 1000


let warningTimeout: NodeJS.Timeout = setInterval(() => { null }, 0)

@injectable()
@SingletonService
export default class TokenTimeoutService {

  private listening = false
  private inactivityModalUtil = $inj(InactivityModalUtil)

  updateTimeout(timestamp: string) {
    if(this.listening) {
      const newValue = new Date(timestamp).getTime()
      tokenTimeoutMilliseconds = newValue - Date.now()

      clearTimeout(warningTimeout)
      warningTimeout = setInterval(
        () => {
          tokenTimeoutMilliseconds -= oneSecond
          if (tokenTimeoutMilliseconds <= timeoutWarningThresholdSeconds * oneSecond) {
            this.inactivityModalUtil.prompt()
            clearTimeout(warningTimeout)
          }
        },
        oneSecond,
      )
    }
  }

  trackTimeout() {
    this.listening = true
  }

  removeTimeout() {
    clearTimeout(warningTimeout)
    this.listening = false
  }
}

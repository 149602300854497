import {SingletonService} from '@/common/dependency.configs'
import {inject, injectable} from 'inversify'
import {HttpHandler} from '@/common/services/services.module'

@injectable()
@SingletonService
export default class PingService {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
  ) {}

  ping = () => {
    return this.httpHandler.get("/v1/ping")
  }
}

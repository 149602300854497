import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type IApplicant from '@/common/models/IApplicant'

@injectable()
class EnrollmentApplicantFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getEnrollmentApplicantList(uuid: string, enrollmentId: number):Promise<Array<IApplicant>>  {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/applicant`,
      {},
      ResponseTypes.Payload
    );
  }
}
export default EnrollmentApplicantFactory;

import HttpHandler from "@/common/services/connect/HttpHandler";
import { injectable, inject } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class ApplicantCoreFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}
  get(coreId: string, applicantType: string) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH_CORE_URL}/applicant/${applicantType}/${coreId}`,
      {},
      ResponseTypes.Payload
    ).then(function (response) {
      // Convert the person's dateOfBirth to "YYYY-MM-DD" format by removing the time/timezone component
      // ("T00:00:00.000Z") from the end.  This will cause the date to be both parsed and displayed according to the
      // local (browser) timezone, thus preventing any inadvertent conversions between UTC and local time.
      if (response?.person?.dateOfBirth?.length > 10)
      {
        response.person.dateOfBirth = response.person.dateOfBirth.substring(0, 10);
      }

      return response;
    });
  }
}
export default ApplicantCoreFactory;

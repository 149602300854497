import { inject, injectable } from "inversify";
import {isUndefined} from "lodash";
import TimeoutService from "@/common/services/utils/TimeoutService";

@injectable()
class ScrollToService {
  private scrollBuffer = 50;

  constructor(@inject(TimeoutService) private timeoutService: TimeoutService) {}

  async scrollDivToId(containerId: string, scrollToId: string, delay = 1) {
    return this.timeoutService.timeoutPromise(delay).then(() => {
      const container = document.getElementById(containerId);
      const scrollTo = document.getElementById(scrollToId);
      scrollTo?.scrollIntoView();
      if (undefined != container && undefined != scrollTo) {
        const containerSize = container.clientHeight,
          elementSize = scrollTo.clientHeight;

        //Only scroll if the element is off the bottom of the page
        if (scrollTo.offsetTop + elementSize - container.scrollTop > containerSize - this.scrollBuffer) {
          container.scrollTop += elementSize;
        }
        if (scrollTo.offsetTop < container.scrollTop) {
          container.scrollTop = scrollTo.offsetTop;
        }
      }
    });
  }

  async scrollDivToIdWithOffset(containerId: string, scrollToId: string, delay = 1, offset: number) {
    const container: any = document.getElementById(containerId);
    const scrollTo: any = document.getElementById(scrollToId);

    container[0].scrollTop = 0;
    scrollTo.removeClass("scroll-target");
    return this.timeoutService.timeoutPromise(delay).then(() => {
      scrollTo.addClass("scroll-target");
      container[0].scrollTop =
        scrollTo[0].getBoundingClientRect().top - container[0].getBoundingClientRect().top - offset;
    });
  }

  async scrollDivToBottom(containerId: string, delay = 1) {
    return this.timeoutService.timeoutPromise(delay).then(() => {
      const containers = document.querySelectorAll("#" + containerId);

      containers.forEach(function (container) {
        if (!isUndefined(container)) {
          container.scrollTop = container.scrollHeight;
        }
      });
    });
  }

  async scrollWindowToId(scrollToId: string, delay = 1) {
    return this.timeoutService.timeoutPromise(delay).then(() => {
      const scrollTo = document.getElementById(scrollToId);
      if (undefined != scrollTo) {
        window.scrollTo(0, scrollTo.offsetTop);
      }
    });
  }
}

export default ScrollToService;

<template>
  <blt-modal-dialog
      :show-dialog="showDialog"
      ref="inactivity-dialog"
      confirm-label="I'm still here"
      title="Are you still there?"
      @confirm="close()"
      show-confirm
      type="small-dialog"
      :click-outside-to-close=false>
    <template #dialog-content>
      <p>Your session will timeout due to inactivity when the timer ends.</p>
      <h1>{{ Math.floor(timeRemaining / 60) }}:{{ (timeRemaining % 60).toString().padStart(2, '0') }}</h1>
    </template>
  </blt-modal-dialog>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue'
import BltModalDialog from '@/common/components/bltDialog/bltModalDialog.vue'
import {tokenTimeoutMilliseconds} from '@/open/services/TokenTimeoutService'
import {$inj} from '@/common/decorators/depinject'
import PingService from '@/open/services/PingService'
import ActiveUserKeepAliveService from '@/common/services/KeepaliveService/ActiveUserKeepAliveService'

const timeRemaining = ref<number>(0)
const showDialog = ref<boolean>(false)
const pingService = $inj(PingService)
const activeUserKeepAliveService = $inj(ActiveUserKeepAliveService)
let interval = setInterval(() => { null })
const oneSecond = 1000

const close = () => {
  showDialog.value = false
  clearInterval(interval)
  activeUserKeepAliveService.startListening()
  pingService.ping()
}

onMounted(() => {
  activeUserKeepAliveService.stopListening()
  timeRemaining.value = Math.floor(tokenTimeoutMilliseconds/1000)
  showDialog.value = true

  interval = setInterval(() => {
    timeRemaining.value--
    if (timeRemaining.value < -1) {
      close()
    }
  }, oneSecond)
})

</script>

<style>
</style>
